import clientConfig from '~/clientConfig';
import { FeatureKeys, FeatureKeyType, FeatureValueKeys } from '~/constants/features';

const isOverridable = clientConfig.postHog.overridableUrls.some((url) => clientConfig.baseUrl.includes(url));

const parseJson = (json: string | null) => {
  if (!json) return null;
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
};

const getFeatureOverride = (key: FeatureKeys | FeatureValueKeys) => {
  const featureOverrides = parseJson(localStorage.getItem('feature-overrides')) as FeatureKeyType | null;
  return isOverridable ? featureOverrides?.[key] : null;
};

export default getFeatureOverride;
