interface FlagComponentProps {
  country: string;
  countryName: string;
}

export default function FlagComponent({ country, countryName }: FlagComponentProps) {
  return (
    <div className="tw-relative tw-h-4 tw-w-6">
      {country && (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className="tw-absolute tw-inset-0 tw-w-full tw-h-full tw-object-cover tw-rounded-[4px] tw-border"
          title={countryName}
          src={`/flags/${country.toLowerCase()}.svg`}
          alt={`${countryName} flag`}
        />
      )}
    </div>
  );
}
