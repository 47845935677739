import * as React from 'react';
import { CircleCheck, Lock, LucideProps } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { cn } from '~/shadcn/utils';

type CardVariant = 'primary' | 'success' | 'white';
type CardContextType = {
  variant: CardVariant;
  asButton: boolean;
  isHovered: boolean;
};
export type IconType = React.ForwardRefExoticComponent<
  Omit<LucideProps, 'ref'> & React.RefAttributes<SVGSVGElement>
>;

const CardVariantContext = React.createContext<CardContextType>({
  variant: 'primary',
  asButton: false,
  isHovered: false
});

type VariantStyle = {
  card: string;
  title: string;
  description: string;
  mainText: string;
  footer: string;
  icon: string;
};

const variantStyles: Record<
  CardVariant,
  VariantStyle | ((asButton: boolean, isHovered: boolean) => VariantStyle)
> = {
  primary: (asButton: boolean, isHovered: boolean) => ({
    // Background
    card: cn('tw-bg-primary tw-text-primary-foreground tw-group', asButton && 'hover:tw-bg-blue-200'),

    // Text
    title: cn('tw-text-muted group-hover:tw-text-muted', {
      '!tw-text-neutral-500': asButton && isHovered
    }),
    description: cn('tw-text-muted', {
      '!tw-text-neutral-500': asButton && isHovered
    }),
    mainText: cn('tw-text-white text-start', {
      '!tw-text-primary': asButton && isHovered
    }),
    footer: cn('tw-text-white', {
      '!tw-text-primary': asButton && isHovered
    }),

    // Icon
    icon: 'tw-text-muted'
  }),
  white: (asButton: boolean, isHovered: boolean) => ({
    // Background
    card: cn('tw-bg-white', asButton && 'hover:tw-bg-blue-200'),

    // Text
    title: cn('tw-text-muted', {
      '!tw-text-neutral-500': asButton && isHovered
    }),
    description: cn('tw-text-muted', {
      '!tw-text-neutral-500': asButton && isHovered
    }),
    mainText: cn('tw-text-primary', {
      '!tw-text-primary': asButton && isHovered
    }),
    footer: cn('tw-text-primary', {
      '!tw-text-primary': asButton && isHovered
    }),

    // Icon
    icon: 'tw-text-muted'
  }),
  success: {
    // Background
    card: 'tw-bg-green-100 tw-text-success',

    // Text
    title: 'tw-text-muted',
    description: 'tw-text-muted',
    mainText: 'tw-text-success',
    footer: 'tw-text-success',

    // Icon
    icon: 'tw-text-success'
  }
};

function getVariantStyles(variant: CardVariant, asButton: boolean, isHovered = false): VariantStyle {
  const styles = variantStyles[variant];
  if (typeof styles === 'function') {
    return styles(asButton, isHovered);
  }
  return styles;
}

interface CardBaseProps {
  variant?: CardVariant;
  className?: string;
  children?: React.ReactNode;
}

interface CardDivProps extends CardBaseProps, React.HTMLAttributes<HTMLDivElement> {
  asButton?: false;
  ref?: React.Ref<HTMLDivElement>;
}

interface CardButtonProps extends CardBaseProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  asButton: true;
  ref?: React.Ref<HTMLButtonElement>;
}

type CardProps = CardDivProps | CardButtonProps;

function Card({ className, variant = 'primary', asButton = false, ...props }: CardProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const Component = asButton ? 'button' : 'div';
  const styles = getVariantStyles(variant, asButton, isHovered);
  const contextValue = React.useMemo(
    () => ({ variant, asButton, isHovered }),
    [variant, asButton, isHovered]
  );

  return (
    <CardVariantContext.Provider value={contextValue}>
      <Component
        className={cn(
          // Layout
          'tw-rounded-xl tw-flex tw-p-5 tw-transition tw-duration-200',

          // Border
          'tw-border',

          // Transition
          'tw-transition tw-duration-200',

          // Variant Styles
          styles.card,
          className
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        {...(props as React.ButtonHTMLAttributes<HTMLButtonElement> & React.HTMLAttributes<HTMLDivElement>)}
      />
    </CardVariantContext.Provider>
  );
}

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        // Layout
        'tw-flex tw-flex-row tw-justify-between tw-w-full',
        className
      )}
      {...props}
    />
  )
);
CardHeader.displayName = 'CardHeader';

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, children, ...props }, ref) => {
    const { variant, asButton, isHovered } = React.useContext(CardVariantContext);
    const styles = getVariantStyles(variant, asButton, isHovered);
    return (
      <h3
        ref={ref}
        className={cn(
          // Text
          'tw-font-semibold tw-text-sm tw-mt-0',
          styles.title,
          className
        )}
        {...props}
      >
        {children}
      </h3>
    );
  }
);
CardTitle.displayName = 'CardTitle';

const CardDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { variant, asButton, isHovered } = React.useContext(CardVariantContext);
    const styles = getVariantStyles(variant, asButton, isHovered);
    return (
      <p
        ref={ref}
        className={cn(
          // Text
          'tw-text-sm tw-font-medium',
          styles.description,
          className
        )}
        {...props}
      />
    );
  }
);
CardDescription.displayName = 'CardDescription';

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { variant, asButton, isHovered } = React.useContext(CardVariantContext);
    const styles = getVariantStyles(variant, asButton, isHovered);
    return (
      <div
        ref={ref}
        className={cn(
          className,
          // Layout
          'tw-pt-0 tw-flex tw-flex-col tw-gap-2',
          styles.mainText
        )}
        {...props}
      />
    );
  }
);
CardContent.displayName = 'CardContent';

const InfoCardMainText = React.forwardRef<HTMLHeadingElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, children, ...props }, ref) => (
    <h2
      ref={ref}
      className={cn(
        // Text
        'tw-text-4xl tw-font-semibold',
        className
      )}
      {...props}
    >
      {children}
    </h2>
  )
);
InfoCardMainText.displayName = 'InfoCardMainText';

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { variant, asButton, isHovered } = React.useContext(CardVariantContext);
    const styles = getVariantStyles(variant, asButton, isHovered);
    return (
      <div
        ref={ref}
        className={cn(
          // Layout
          'tw-flex tw-items-center',
          styles.footer,
          className
        )}
        {...props}
      />
    );
  }
);
CardFooter.displayName = 'CardFooter';

function CardIcon({ className, icon, ...props }: { className?: string; icon: React.ReactNode }) {
  return (
    <div
      className={cn(
        // Layout
        'tw-icon',
        className
      )}
      {...props}
    >
      {icon}
    </div>
  );
}

interface InfoCardProps {
  title: string;
  mainText: string;
  description?: string;
  footerText: string;
  progress: 'nextStep' | 'completed' | 'inProgress' | 'locked';
  className?: string;
  defaultIcon: IconType;
  onClick?: () => void;
  collapsible?: boolean;
  dataTestId?: string;
}

const mapProgressToVariant = {
  nextStep: 'white',
  completed: 'success',
  inProgress: 'primary',
  locked: 'white'
} as const;

const mapProgressToIcon = {
  nextStep: null,
  completed: CircleCheck,
  inProgress: null,
  locked: Lock
} as const;

function InfoCard({
  className,
  progress,
  title,
  mainText,
  description,
  footerText,
  defaultIcon,
  onClick,
  collapsible,
  dataTestId,
  ...props
}: InfoCardProps) {
  const variant = mapProgressToVariant[progress];
  const Icon = mapProgressToIcon[progress] || defaultIcon;
  const asButton = !!onClick && (progress === 'inProgress' || progress === 'nextStep');
  if (collapsible && progress === 'completed')
    return (
      <Card
        className={cn(
          // Layout
          'tw-flex-col tw-items-start tw-justify-start',
          className
        )}
        variant={variant}
        {...props}
        asButton={asButton}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <CardHeader className="tw-flex tw-items-center tw-justify-start">
          <CardTitle className="tw-capitalize tw-mb-0">{title}</CardTitle>
          <CardIcon
            icon={
              <Icon
                className={cn(
                  // Layout
                  'tw-w-5 tw-h-5',
                  // Text
                  getVariantStyles(variant, asButton).icon
                )}
              />
            }
          />
        </CardHeader>
      </Card>
    );
  return (
    <Card
      className={cn(
        // Layout
        'tw-flex-col tw-items-start tw-justify-start',
        className
      )}
      variant={variant}
      {...props}
      asButton={asButton}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <CardHeader className="tw-pb-4">
        <CardTitle className="tw-capitalize">{title}</CardTitle>
        <CardIcon
          icon={
            <Icon
              className={cn(
                // Layout
                'tw-w-10 tw-h-10',
                // Text
                getVariantStyles(variant, asButton).icon
              )}
            />
          }
        />
      </CardHeader>
      <CardContent className="tw-text-left">
        <InfoCardMainText className="tw-text-4xl ">{mainText}</InfoCardMainText>
        {description && <CardDescription>{description}</CardDescription>}
      </CardContent>
      <CardFooter className="tw-mt-auto">
        <p
          className={cn(
            // Text
            'tw-text-md tw-font-medium tw-mb-0 tw-mt-auto'
          )}
        >
          {footerText}
        </p>
      </CardFooter>
    </Card>
  );
}

const HorizontalCardButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    selected: boolean;
    onClick: () => void;
    Icon: IconType;
    text: string;
  }
>(({ className, children, selected, onClick, Icon, text, ...props }, ref) => {
  const variant = selected ? 'success' : 'white';
  return (
    <Card
      asButton
      className={cn(
        // Layout
        'tw-flex tw-flex-row tw-justify-start tw-content-center tw-items-center tw-gap-5',
        className
      )}
      variant={variant}
      onClick={onClick}
      {...props}
      ref={ref}
    >
      <Icon
        className={cn(
          // Layout
          'tw-w-10 tw-h-10 tw-my-3',
          // Text
          { 'tw-text-success': selected, 'tw-text-primary': !selected }
        )}
        absoluteStrokeWidth
        strokeWidth={1.5}
      />
      <div
        className={cn(
          // Layout
          'tw-inline-block tw-h-full tw-w-[1px] tw-self-stretch',
          // Background
          { 'tw-bg-neutral-200': !selected, 'tw-bg-green-200': selected }
        )}
      />
      <p
        className={cn(
          // Text
          'tw-m-0',
          // Text
          { 'tw-text-success': selected, 'tw-text-muted': !selected }
        )}
      >
        {text}
      </p>
    </Card>
  );
});
HorizontalCardButton.displayName = 'HorizontalCardButton';

type HorizontalCardProps = Omit<CardDivProps, 'asChild'> & {
  Icon?: IconType;
  text: string;
};

function HorizontalCard({ className, children, Icon, text, variant, ...props }: HorizontalCardProps) {
  return (
    <Card
      className={cn(
        className,
        // Layout
        'tw-flex tw-flex-col md:tw-flex-row tw-justify-start tw-content-center tw-items-center md:tw-h-20 tw-h-auto tw-gap-5',
        {
          'tw-justify-between': !Icon
        }
      )}
      variant={variant}
      {...props}
    >
      {Icon && (
        <>
          <Icon
            className={cn(
              // Layout
              'tw-w-10 tw-h-10 tw-my-3 tw-text-blue-500 md:tw-block tw-hidden'
            )}
            absoluteStrokeWidth
            strokeWidth={1.5}
          />
          <div
            className={cn(
              // Layout
              'tw-inline-block tw-h-full tw-w-[1px] tw-self-stretch tw-bg-neutral-200'
            )}
          />
        </>
      )}

      <p
        className={cn(
          // Layout
          'tw-m-0 tw-mr-auto tw-text-start',
          {
            'tw-max-w-96': !Icon
          }
        )}
      >
        {text}
      </p>
      {children}
    </Card>
  );
}

type ImageCardProps = {
  width?: number;
  height?: number;
  imgSrc: string;
  imgAlt: string;
  title: string;
  linkText: string;
  href: string;
  description: string;
  likeWithArrow?: boolean;
};
function ImageCard({
  width = 450,
  height = 200,
  imgSrc,
  imgAlt,
  linkText,
  href,
  likeWithArrow = false,
  title,
  description
}: ImageCardProps) {
  return (
    <Card
      className={cn(
        // Layout
        'tw-flex tw-flex-col tw-justify-start tw-items-start tw-pl-0 tw-pr-0 tw-pt-0 tw-pb-0 tw-relative'
      )}
      variant="white"
    >
      <CardHeader className="tw-full tw-h-auto">
        <Image
          src={imgSrc}
          alt={imgAlt}
          width={width}
          height={height}
          className="tw-rounded-t-xl tw-w-full tw-object-cover"
        />
      </CardHeader>
      <CardContent className="tw-px-5 tw-py-6 tw-pt-5 tw-text-neutral-900">
        <CardTitle className="tw-text-xl tw-text-neutral-900 tw-mb-0">{title}</CardTitle>
        <p className="tw-py-0 tw-mb-0 !tw-text-neutral-900">{description}</p>
        <Link href={href}>
          {linkText} {likeWithArrow && '→'}
        </Link>
      </CardContent>
    </Card>
  );
}

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
  InfoCardMainText,
  HorizontalCardButton,
  InfoCard,
  CardIcon,
  ImageCard,
  HorizontalCard
};
export default Card;
