import { useCallback, useMemo, useState } from 'react';
import AppContext, { AppContextState, ContentLoaderState } from './AppContext';

const initialContentLoaderState = { loading: false };

export default function AppContextProvider({ children }: { children: React.ReactNode }) {
  const [contentLoaderState, setContentLoaderState] = useState<ContentLoaderState>(initialContentLoaderState);

  const startContentLoading = useCallback((subtitle?: ContentLoaderState['subtitle']) => {
    setContentLoaderState({ loading: true, subtitle });
  }, []);

  const finishContentLoading = useCallback(() => {
    setContentLoaderState(initialContentLoaderState);
  }, []);

  const context = useMemo<AppContextState>(
    () => ({
      finishContentLoading,
      startContentLoading,
      contentLoaderState
    }),
    [contentLoaderState, finishContentLoading, startContentLoading]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
