import Decimal from 'decimal.js';
import { calculateDiscountRate } from './calculateDiscountRate';
/**
 * Calculates the invoice totals. Rates will be converted from percentages to fractions if needed.
 */
export function calculateInvoiceTotals({ user, baseRate, earlyPaymentRate, invoice, taxAmount }) {
    // specific rates for referral campaigns
    if (user?.referral?.toLowerCase() === 'mma') {
        baseRate = new Decimal(0.05);
    }
    // override the base rate with the user's base rate
    if (user?.baseRate) {
        baseRate = user.baseRate;
    }
    // override the rates with the company's rates
    if (invoice.company) {
        if (invoice.company.feeRate !== null) {
            baseRate = invoice.company.feeRate;
        }
        if (invoice.company.earlyPaymentFeeRate != null) {
            earlyPaymentRate = invoice.company.earlyPaymentFeeRate;
        }
    }
    // convert base rate from a percentage to a fraction, so 5% = 0.05
    if (baseRate.greaterThanOrEqualTo(1))
        baseRate = baseRate.dividedBy(100);
    if (earlyPaymentRate.greaterThanOrEqualTo(1))
        earlyPaymentRate = earlyPaymentRate.dividedBy(100);
    const invoiceTotalDkk = (invoice.subtotalDkk ?? 0).times(invoice.includeVat ? taxAmount.plus(1) : new Decimal(1));
    // default the paid amount to the invoice total in dkk
    let { paidAmountDkk } = invoice;
    if ((invoice.status === 'PAID' || invoice.status === 'SALARY_PAID_CUSTOMER_PAID') && !paidAmountDkk) {
        paidAmountDkk = invoiceTotalDkk;
    }
    // if the status becomes draft again, then reset the paid amount
    if (invoice.status === 'DRAFT' && paidAmountDkk) {
        paidAmountDkk = null;
    }
    let invoiceRate = baseRate.plus(invoice.earlyPayment ? earlyPaymentRate : 0);
    invoiceRate = Decimal.max(0, invoiceRate);
    // once the invoice has been paid then we use the paid amount instead of the calculated total
    let totalForFee = paidAmountDkk ?? invoiceTotalDkk;
    // fee is calculated on the amount before VAT
    if (invoice.includeVat) {
        totalForFee = totalForFee.dividedBy(taxAmount.plus(1));
    }
    const feeAmountDkk = totalForFee.times(invoiceRate);
    const discountRate = calculateDiscountRate(feeAmountDkk, invoice.discountType, invoice.discountValue, invoice.discountMaxValue);
    const discountAmountDkk = feeAmountDkk.times(discountRate);
    return {
        subtotalDkk: invoice.subtotalDkk,
        totalDkk: invoiceTotalDkk,
        feeRate: invoiceRate,
        feeAmountDkk: feeAmountDkk,
        discountedFeeAmountDkk: feeAmountDkk.minus(discountAmountDkk),
        paidAmountDkk
    };
}
export default calculateInvoiceTotals;
