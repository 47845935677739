import Decimal from 'decimal.js';
const rates = [
    {
        date: new Date(Date.UTC(2024, 0, 1)),
        highRate: 3.79,
        lowRate: 2.23
    },
    {
        date: new Date(Date.UTC(2023, 0, 1)),
        highRate: 3.73,
        lowRate: 2.19
    },
    {
        date: new Date(Date.UTC(2022, 4, 1)),
        highRate: 3.7,
        lowRate: 2.17
    },
    {
        date: new Date(Date.UTC(2022, 0, 1)),
        highRate: 3.51,
        lowRate: 1.98
    },
    {
        date: new Date(Date.UTC(2021, 0, 1)),
        highRate: 3.44,
        lowRate: 1.9
    },
    {
        date: new Date(Date.UTC(2020, 0, 1)),
        highRate: 3.52,
        lowRate: 1.96
    }
];
/**
 * Get the mileage rates for a specific date
 * @param date The date for which to get the mileage rates
 * @returns The mileage rates for the given date
 */
export function getMileageRatesForDate(date) {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const currentRates = rates.filter((d) => d.date <= date)?.[0];
    if (!currentRates) {
        throw new Error('No mileage rates found for date');
    }
    return { highRate: new Decimal(currentRates.highRate), lowRate: new Decimal(currentRates.lowRate) };
}
