import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import SidebarNav from './SidebarNav';
import Logo from '~/features/shared/components/Logo';
import { MEDIUM_BREAKPOINT, MOBILE_BREAKPOINT } from '~/features/shared/constants';

type SidebarProps = {
  open: boolean;
};

function Sidebar({ open }: SidebarProps): JSX.Element {
  const nodeRef = React.useRef(null);
  return (
    <CSSTransition nodeRef={nodeRef} in={open} classNames="sidebar" timeout={500}>
      <div ref={nodeRef} className={classNames('leftside-menu d-print-none', { open })}>
        <div className="logo-container">
          <Logo width="100%" height="70px" variant="light" />
        </div>

        <div className="leftside-menu-container">
          <SidebarNav />
        </div>
        <style jsx>{`
          @media (max-width: ${MEDIUM_BREAKPOINT}px) {
            .leftside-menu {
              display: block;
              margin-top: 70px;
              padding-top: 10px;
              border-right: 1px solid #2b4881;
              z-index: 99999 !important;
            }
            .logo-container {
              display: none;
            }
          }

          :global(.sidebar-enter),
          :global(.sidebar-exit-done) {
            transform: translate(-260px);
          }
          :global(.sidebar-enter-active) {
            transform: translate(0);
            transition: transform 500ms;
          }

          :global(.sidebar-exit) {
            transform: translate(0px);
          }
          :global(.sidebar-exit-active) {
            transform: translate(-260px);
            transition: transform 500ms;
          }

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            :global(.sidebar-enter),
            :global(.sidebar-exit-done) {
              transform: translate(-100vw);
            }
            :global(.sidebar-enter-active) {
              transform: translate(0);
              transition: transform 500ms;
            }

            :global(.sidebar-exit) {
              transform: translate(0px);
            }
            :global(.sidebar-exit-active) {
              transform: translate(-100vw);
              transition: transform 500ms;
            }
          }
        `}</style>
      </div>
    </CSSTransition>
  );
}

export default Sidebar;
