import * as React from 'react';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import * as SelectPrimitive from '@radix-ui/react-select';

import { cn } from '~/shadcn/utils';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    invalid?: boolean;
    name?: string;
  }
>(({ className, name, children, invalid, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'tw-group',

      // Layout
      'tw-flex tw-h-12 tw-w-full tw-items-center tw-justify-between tw-whitespace-nowrap tw-rounded-sm',

      // Padding
      'tw-px-3 tw-py-2',

      // Border
      'tw-border tw-border-input-border enabled:hover:tw-border-input-border-hover',

      // Background
      'tw-bg-white disabled:tw-bg-input-disabled',

      // Text
      'tw-text-sm data-[placeholder]:tw-text-input-text-placeholder',

      // Transition
      'tw-transition-colors',

      // Outline
      'focus-visible:tw-outline-none',

      // Focused
      'data-[state=open]:tw-ring-input-ring data-[state=open]:tw-ring-2 data-[state=open]:tw-ring-offset-2 focus-visible:tw-ring-input-ring focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2',

      // Read-only
      'read-only:tw-bg-input-disabled read-only:tw-ring-0',

      // Disabled
      'disabled:tw-cursor-not-allowed disabled:tw-text-input-text-disabled disabled:tw-bg-input-background-disabled',
      {
        'enabled:tw-border-input-border-invalid enabled:hover:tw-border-input-border-invalid-hover': invalid
      },
      className
    )}
    {...props}
    data-testid={`${name || 'select'}-trigger`}
    tabIndex={0}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDownIcon className="tw-h-4 tw-w-4 tw-transition-transform tw-duration-200 group-data-[state='open']:tw-rotate-180 tw-text-neutral-500" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      // Layout
      'tw-flex tw-cursor-default tw-items-center tw-justify-center tw-py-1',
      className
    )}
    {...props}
  >
    <ChevronUpIcon />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      // Layout
      'tw-flex tw-cursor-default tw-items-center tw-justify-center tw-py-1',
      className
    )}
    {...props}
  >
    <ChevronDownIcon />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
  // eslint-disable-next-line react/prop-types
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        // Layout
        'tw-relative tw-z-dialogOverlay tw-max-h-96 tw-min-w-[8rem] tw-overflow-hidden tw-rounded-md',

        // Border
        'tw-border',

        // Background
        'tw-bg-popover',

        // Text
        'tw-text-popover-foreground',

        // Shadow
        'tw-shadow-md',

        // State and Animation
        'data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:tw-translate-y-1 data-[side=left]:tw--translate-x-1 data-[side=right]:tw-translate-x-1 data-[side=top]:tw--translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          // Padding
          'tw-p-1',

          position === 'popper' &&
            'tw-h-[var(--radix-select-trigger-height)] tw-w-full tw-min-w-[var(--radix-select-trigger-width)]'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn(
      // Padding
      'tw-px-2 tw-py-1.5',

      // Text
      'tw-text-sm tw-font-semibold',
      className
    )}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      // Layout
      'tw-relative tw-flex tw-w-full tw-cursor-default tw-select-none tw-items-center tw-rounded-sm',

      // Padding
      'tw-py-1.5 tw-pl-2 tw-pr-8',

      // Text
      'tw-text-sm',

      // Outline
      'tw-outline-none',

      // State and Disabled
      'hover:tw-cursor-pointer focus:tw-bg-accent focus:tw-text-accent-foreground data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50',
      className
    )}
    {...props}
  >
    <span className="tw-absolute tw-right-2 tw-flex tw-h-3.5 tw-w-3.5 tw-items-center tw-justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="tw-h-4 tw-w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>
    <SelectPrimitive.ItemText className="dupa">{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn(
      // Margin and Layout
      'tw--mx-1 tw-my-1 tw-h-px',

      // Background
      'tw-bg-muted',
      className
    )}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton
};
