import { AM_CONTRIBUTION_PERCENTAGE, EXPENSES_PERCENTAGE, FACTOFLY_EXPENSE_PERCENTAGE, SALARY_CALCULATOR_INVOICE_AMOUNT_MAX, SALARY_CALCULATOR_INVOICE_AMOUNT_MIN } from './constants';
export function salaryCalculator({ invoiceAmountInput, taxInput, expensesInput, taxDeductionInput }) {
    let expenses = expensesInput * EXPENSES_PERCENTAGE;
    const factoflyExpense = invoiceAmountInput * FACTOFLY_EXPENSE_PERCENTAGE;
    const cappedExpenses = Math.min(expenses, invoiceAmountInput - factoflyExpense);
    expenses = Math.min(expenses, cappedExpenses);
    const amContributionBasis = invoiceAmountInput - factoflyExpense - cappedExpenses;
    const amContribution = amContributionBasis * AM_CONTRIBUTION_PERCENTAGE;
    const taxBasis = amContributionBasis - amContribution;
    const tax = ((taxBasis - taxDeductionInput) / 100) * taxInput;
    const calculatorTotal = taxBasis - tax + cappedExpenses;
    const invoiceAmountPercent = ((invoiceAmountInput - SALARY_CALCULATOR_INVOICE_AMOUNT_MIN) /
        (SALARY_CALCULATOR_INVOICE_AMOUNT_MAX - SALARY_CALCULATOR_INVOICE_AMOUNT_MIN)) *
        100;
    return {
        expenses,
        factoflyExpense,
        cappedExpenses,
        amContributionBasis,
        amContribution,
        taxBasis,
        tax,
        calculatorTotal,
        invoiceAmountPercent
    };
}
