import Decimal from 'decimal.js';
import { getPaidOutSalaryLine } from './getPaidOutSalaryLine';
export function getAdjustmentPaymentAmount(payrollUser, employeePayroll, forPayment) {
    if (!payrollUser)
        return new Decimal(0);
    const paidSalaries = payrollUser.salary?.filter((salary) => (salary.status === 'PAID' || salary.status === 'SENT') && salary.paymentAmount !== null) || [];
    const paidSalariesTotal = paidSalaries.reduce((acc, salary) => acc.add(salary?.paymentAmount || new Decimal(0)), new Decimal(0));
    const paychecksPaidOutLines = getPaidOutSalaryLine(employeePayroll) ?? [];
    const paychecksPaidOutTotal = paychecksPaidOutLines.reduce((acc, paycheck) => acc.add(paycheck.paymentPerUnit * paycheck.unit), new Decimal(0));
    // difference between the sum of the salary payment amounts and the forPayment + Allerede udbetalt løn
    const adjustmentPaymentAmount = paidSalariesTotal
        .sub(paychecksPaidOutTotal)
        .sub(forPayment)
        .times(100)
        .round()
        .div(100);
    return adjustmentPaymentAmount;
}
