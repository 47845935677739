import { useTranslation } from 'next-i18next';
import { Button, ButtonProps } from '~/shadcn/ui/button';
import { cn } from '~/shadcn/utils';

export type SubmitButtonProps = {
  title: string | JSX.Element;
  className?: string;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick?: ButtonProps['onClick'];
  variant?: ButtonProps['variant'];
  fullWidth?: boolean;
  submitting?: boolean;
  submittingTitle?: string;
  testId?: string;
  autoFocus?: boolean;
};

function SubmitButton({
  icon,
  title,
  submitting,
  className,
  onClick,
  disabled,
  variant,
  fullWidth,
  submittingTitle,
  autoFocus,
  testId
}: SubmitButtonProps) {
  const { t: tCommon } = useTranslation('common');

  const submittingText = submittingTitle || `${tCommon('buttons.saving')}...`;

  const iconElement = icon ? <>{icon} </> : undefined;

  return (
    <Button
      className={cn(className, { 'tw-w-full': fullWidth })}
      type={onClick ? 'button' : 'submit'}
      onClick={onClick}
      variant={variant}
      disabled={submitting || disabled}
      data-testid={testId}
      autoFocus={autoFocus}
    >
      {iconElement}
      {submitting ? submittingText : title}
    </Button>
  );
}

export default SubmitButton;
