import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import { cn } from '~/shadcn/utils';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      // Position
      'tw-fixed tw-inset-0 tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 ',

      // Layout
      'tw-grid tw-place-items-center tw-overflow-y-auto',

      // Z-Index
      'tw-z-dialogOverlay',

      // Background
      'tw-bg-black/80',

      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay>
      <DialogPrimitive.Content
        ref={ref}
        className={cn(
          // Position
          'tw-relative',

          // Z-Index
          'tw-z-dialogOverlay',

          // Grid
          'tw-grid',

          // Width
          'tw-w-full tw-max-w-lg',

          // Spacing
          'tw-gap-4 tw-p-6',

          // Border
          'tw-border',

          // Background
          'tw-bg-background',

          // Shadow
          'tw-shadow-lg',

          // Duration
          'tw-duration-200',

          // Responsive
          'sm:tw-rounded-lg',
          className
        )}
        {...props}
      >
        {children}
        <DialogPrimitive.Close
          className={cn(
            // Layout
            'tw-absolute tw-right-4 tw-top-4',

            // Spacing
            'tw-ring-offset-background tw-ring-offset-2',

            // Border
            'tw-rounded-sm',

            // Opacity
            'tw-opacity-70 hover:tw-opacity-100',

            // Ring
            'focus:tw-ring-2 focus:tw-ring-ring',

            // Transition
            'tw-transition-opacity',

            // State
            'focus:tw-outline-none disabled:tw-pointer-events-none data-[state=open]:tw-bg-accent data-[state=open]:tw-text-muted-foreground'
          )}
        >
          <Cross2Icon className="tw-h-4 tw-w-4" />
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogOverlay>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

function DialogHeader({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('tw-flex tw-flex-col tw-space-y-1.5 tw-text-center tw-border-b', className)}
      {...props}
    />
  );
}
DialogHeader.displayName = 'DialogHeader';

function DialogFooter({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'tw-flex tw-flex-col-reverse sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2',
        className
      )}
      {...props}
    />
  );
}
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      'tw-text-lg tw-font-semibold tw-leading-none tw-tracking-tight tw-archivo-black-font',
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn('tw-text-sm tw-text-muted-foreground', className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription
};
