import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '~/shadcn/utils';

const buttonVariants = cva(
  [
    // Layout
    'tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-sm',

    // Text
    'tw-text-sm tw-font-medium',

    // Transition
    'tw-transition-colors',

    // Outline
    'focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-ring',

    // Disabled
    'disabled:tw-cursor-not-allowed'
  ],
  {
    variants: {
      variant: {
        default: [
          // Background
          'tw-bg-blue',

          // Text
          'tw-text-white',

          // State
          'hover:tw-bg-blue-600',

          // Disabled
          'disabled:tw-bg-blue-100 disabled:tw-text-blue-400'
        ],
        success: [
          // Background
          'tw-bg-success',

          // Text
          'tw-text-success-foreground',

          // State
          'hover:tw-bg-success-hover',

          // Disabled
          'disabled:tw-bg-success-disabled disabled:tw-text-success-foreground-disabled'
        ],
        destructive: [
          // Background
          'tw-bg-destructive',

          // Text
          'tw-text-white',

          // State
          'hover:tw-bg-destructive-hover',

          // Disabled
          'disabled:tw-bg-destructive-disabled disabled:tw-text-destructive-foreground-disabled'
        ],
        outline: [
          // Border
          'tw-border tw-border-input',

          // Background
          'tw-bg-background',

          // Text
          'tw-text-primary',

          // State
          'enabled:hover:tw-bg-neutral-100',

          // Disabled
          'disabled:tw-text-neutral-400'
        ],
        secondary: [
          // Background
          'tw-bg-secondary',

          // Text
          'tw-text-secondary-foreground',

          // State
          'hover:tw-bg-secondary-hover',

          // Disabled
          'disabled:tw-bg-neutral-300 disabled:tw-text-neutral-500'
        ],
        ghost: [
          // Text
          'tw-text-neutral-900',

          // State
          'enabled:hover:tw-bg-neutral-100',

          // Disabled
          'disabled:tw-text-neutral-400'
        ],
        link: [
          // Text
          'tw-text-primary',

          // State
          'tw-underline-offset-4 hover:tw-underline'
        ]
      },
      size: {
        default: [
          // Layout
          'tw-h-12 tw-px-4 tw-py-2'
        ],
        sm: [
          // Layout
          'tw-h-8 tw-rounded-md tw-px-3 tw-text-xs'
        ],
        lg: [
          // Layout
          'tw-h-10 tw-rounded-md tw-px-8'
        ],
        icon: [
          // Layout
          'tw-h-12 tw-w-9'
        ]
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  fullWidth?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, fullWidth, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }), { 'tw-w-full': fullWidth })}
        tabIndex={0}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
