import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'next-i18next';
import { checkIfCPRIsUnderage } from '@factofly/domain';
import { NotificationFrequency } from '@factofly/database';
import SettingsFormUpdateMutation from '../SettingsForm/graphql/SettingsFormUpdateMutation.gql';
import { SettingsFormUpdateMutation as SettingsFormUpdateMutationType } from '../SettingsForm/graphql/.generated/SettingsFormUpdateMutation';
import {
  SettingsFormQuery_user as UserType,
  SettingsFormQuery as SettingsFormQueryType
} from '../SettingsForm/graphql/.generated/SettingsFormQuery';
import {
  NotificationFrequencyEnum,
  SalaryPaymentTypeEnum,
  TaxCardEnum,
  UserUpdateInputArgs
} from '~/.generated/globalTypes';
import useEditForm, { EditFormHookProps } from '~/features/forms/hooks/useEditForm';
import SettingsFormQuery from '../SettingsForm/graphql/SettingsFormQuery.gql';
import useUser from '~/contexts/User/useUser';

export type PaymentInfoSettingsForm = {
  id: bigint;

  email?: string;

  addressAddress?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountryId?: bigint;
  canUpdateName?: boolean;

  taxCard?: TaxCardEnum;

  bankName?: string;
  bankAccount?: string;
  bankRegistration?: string;

  bankAccountHidden?: string;
  bankRegistrationHidden?: string;

  personId?: string;
  personIdHidden?: string;

  vacationPayment?: boolean;
  salaryPaymentType?: SalaryPaymentTypeEnum;
  salaryPaymentTypeRequested?: SalaryPaymentTypeEnum;

  canUpdateVacationPayment?: boolean;
  canUpdateTaxInfo?: boolean;
  notificationFrequency?: NotificationFrequencyEnum;
};

export const toFormData = (input: UserType): PaymentInfoSettingsForm => ({
  id: input.id,

  email: input.email,

  addressAddress: input.address?.address,
  addressCity: input.address?.city,
  addressPostalCode: input.address?.postalCode,
  addressCountryId: input.address?.countryId,

  canUpdateName: input.canUpdateName ?? undefined,

  bankName: input.bankAccountHidden?.bankName ?? undefined,
  bankAccountHidden: input.bankAccountHidden?.bankAccount ?? undefined,
  bankRegistrationHidden: input.bankAccountHidden?.bankRegistration ?? undefined,

  taxCard: input.taxInfo?.taxCard ?? undefined,
  personId: input.taxInfo?.personId ?? undefined,
  personIdHidden: input.taxInfoHidden?.personId ?? undefined,

  vacationPayment: input.vacationPayment ?? undefined,
  salaryPaymentTypeRequested: input.salaryPaymentTypeRequested ?? undefined,
  salaryPaymentType: input.salaryPaymentType ?? undefined,

  canUpdateVacationPayment: input.canUpdateVacationPayment ?? undefined,
  canUpdateTaxInfo: input.canUpdateTaxInfo ?? undefined,

  notificationFrequency: input.notificationFrequency ?? undefined
});

const toInputArgs = (input: PaymentInfoSettingsForm): UserUpdateInputArgs => ({
  email: input.email,

  address: input.addressAddress,
  city: input.addressCity,
  postalCode: input.addressPostalCode,
  countryId: input.addressCountryId,

  bankName: input.bankName ?? undefined,
  bankAccount: input.bankAccount ? input.bankAccount : undefined,
  bankRegistration: input.bankRegistration ? input.bankRegistration : undefined,

  personId: input.personId ? input.personId : undefined,
  taxCard: input.taxCard ?? undefined,

  vacationPayment: input.vacationPayment ?? undefined,
  salaryPaymentType: input.salaryPaymentTypeRequested ?? undefined,

  notificationFrequency: input.notificationFrequency ?? undefined
});

export type PaymentInfoSettingsFormProps = Pick<
  EditFormHookProps<PaymentInfoSettingsForm, SettingsFormQueryType, SettingsFormUpdateMutationType>,
  'defaultValues' | 'onCompleted'
>;

const usePaymentInfoSettingsForm = ({ defaultValues, onCompleted }: PaymentInfoSettingsFormProps) => {
  const { t } = useTranslation('users');
  const { id } = useUser();

  const validationSchema = useMemo(() => {
    const CPRRegExp = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])\d{2}[-]?\d{4}$/;
    const bankRegistrationNumberRegExp = /^\d{4}$/;
    const bankAccountNumberRegExp = /^[0-9]{6,10}$/;
    return yup
      .object()
      .shape(
        {
          addressCity: yup.string().notRequired(),
          addressAddress: yup.string().when('addressCountryId', {
            is: (value: string) => {
              if (value?.length) return true;
              return false;
            },
            then: yup.string().required(t('errors.notValid.address')),
            otherwise: yup.string().nullable().notRequired()
          }),
          personId: yup
            .string()
            .nullable()
            .notRequired()
            .when('personIdNew', {
              is: (value: string) => value?.trim().length,
              then: yup.string().matches(CPRRegExp, t('errors.notValid.CPR'))
            })
            .test('isUnderage', t('errors.notValid.underage'), (value) => {
              if (value?.length) {
                return !checkIfCPRIsUnderage(value);
              }
              return true;
            }),
          bankRegistration: yup
            .string()
            .nullable()
            .notRequired()
            .when('bankRegistration', {
              is: (value: string) => value?.trim().length,
              then: yup
                .string()
                .matches(bankRegistrationNumberRegExp, t('errors.notValid.bankRegistrationNumber'))
            }),
          bankAccount: yup
            .string()
            .nullable()
            .notRequired()
            .when('bankAccount', {
              is: (value: string) => value?.trim().length,
              then: yup.string().matches(bankAccountNumberRegExp, t('errors.notValid.bankAccountNumber'))
            }),
          notificationFrequency: yup.mixed().oneOf(Object.values(NotificationFrequency)).required()
        },
        [
          ['bankAccount', 'bankAccount'],
          ['bankRegistration', 'bankRegistration']
        ]
      )
      .required();
  }, [t]);

  return useEditForm({
    defaultValues,
    validationSchema,
    translationKey: 'users',
    parseMutationData: (formData: PaymentInfoSettingsForm) => ({
      variables: { input: toInputArgs(formData), where: { id } }
    }),
    parseLoadFormData: (initialData: SettingsFormQueryType) => toFormData(initialData.user!),
    parseUpdateFormData: (updatedData: SettingsFormUpdateMutationType) => toFormData(updatedData.updateUser!),
    createMutation: SettingsFormUpdateMutation,
    query: SettingsFormQuery,
    queryOptions: { variables: { where: { id } } },
    refetchQueries: ['GetLoggedInUser', 'OnboardingQuery', 'OnboardingQueryV2'],
    onCompleted
  });
};

export default usePaymentInfoSettingsForm;
