import Decimal from 'decimal.js';
import { getMileageRatesForDate } from './getMileageRatesForDate';
const MILEAGE_YEARLY_DISTANCE = 20000; // 20.000 km per year
/***
 * Calculates the mileage rate based on the previous mileage and the distance travelled
 * @param previousMileageKm The mileage the user had before the current trip
 * @param distanceKm The distance travelled in the current trip
 */
export function calculateMileageRate(previousMileageKm, distanceKm, date) {
    const rates = getMileageRatesForDate(date);
    let mileageRate = rates.highRate;
    if (previousMileageKm.greaterThan(MILEAGE_YEARLY_DISTANCE)) {
        mileageRate = rates.lowRate;
    }
    else if (previousMileageKm.plus(distanceKm).greaterThan(MILEAGE_YEARLY_DISTANCE)) {
        // split the mileage so that each part is taxed at the correct rate
        const firstPart = new Decimal(MILEAGE_YEARLY_DISTANCE).minus(previousMileageKm);
        const secondPart = distanceKm.minus(firstPart);
        mileageRate = firstPart.times(rates.highRate).plus(secondPart.times(rates.lowRate)).dividedBy(distanceKm);
        // check that the mileage rate is between the high and low rate
        if (mileageRate > rates.highRate) {
            throw new Error('Error in calculation. Mileage rate is higher than high rate');
        }
        if (mileageRate < rates.lowRate) {
            throw new Error('Error in calculation. Mileage rate is lower than low rate');
        }
    }
    return mileageRate;
}
export default calculateMileageRate;
