import { TaskAutoApprovalRule } from './TaskAutoApprovalRule';
import { CustomerType } from './CustomerType';
import moment from 'moment';
const APPROVED_CREDIT_RATINGS = ['A', 'B', 'C'];
const DENMARK_COUNTRY_ID = 60n;
export const MINIMUM_HOURLY_RATE = 200;
export const MAXIMUM_PAYMENT_AMOUNT = 5000;
export const WORKING_HOURS_PER_DAY = 12;
export function checkTaskAutomaticApproval(task) {
    let approved = true;
    const rejectionReasons = [];
    if (task.user.taskAutoApprovalRule === TaskAutoApprovalRule.ALWAYS_APPROVE &&
        task.customer.taskAutoApprovalRule === TaskAutoApprovalRule.ALWAYS_APPROVE) {
        return {
            approved: true,
            rejectionReasons: []
        };
    }
    const endDate = moment(task.endDate);
    const possibleTaskHours = WORKING_HOURS_PER_DAY + moment.duration(endDate.diff(task.startDate)).asDays() * WORKING_HOURS_PER_DAY;
    if (possibleTaskHours < task.expectedHours) {
        approved = false;
        rejectionReasons.push('errors.notEnoughHoursBetweenDates');
    }
    if (task.customer.countryId !== DENMARK_COUNTRY_ID) {
        approved = false;
        rejectionReasons.push('errors.foreignCustomer');
    }
    if (!task.hasCustomerPaidInvoice) {
        approved = false;
        rejectionReasons.push('errors.noInvoicePaidByCustomer');
    }
    if (task.customer.type === CustomerType.BUSINESS) {
        if (!task.customer.creditRating) {
            approved = false;
            rejectionReasons.push('errors.noCreditRating');
        }
        else if (!APPROVED_CREDIT_RATINGS.includes(task.customer.creditRating)) {
            approved = false;
            rejectionReasons.push('errors.creditRatingTooLow');
        }
        if (!task.customer.companyEmail) {
            approved = false;
            rejectionReasons.push('errors.noRegisteredEmail');
        }
        else if (task.customer.companyEmail?.split('@')[1] !== task.customer.customerEmail?.split('@')[1]) {
            approved = false;
            rejectionReasons.push('errors.differentEmailDomain');
        }
    }
    if (task.customer.type === CustomerType.PRIVATE && task.paymentAmountDkk > MAXIMUM_PAYMENT_AMOUNT) {
        approved = false;
        rejectionReasons.push('errors.paymentAmountTooHigh');
    }
    if (task.user.approvedTaskCount < 2) {
        approved = false;
        rejectionReasons.push('errors.notEnoughApprovedTasks');
    }
    if (task.hourlyRateDkk < MINIMUM_HOURLY_RATE) {
        approved = false;
        rejectionReasons.push('errors.hourlyRateTooLow');
    }
    if (task.user.taskAutoApprovalRule === TaskAutoApprovalRule.NEVER_APPROVE) {
        approved = false;
        rejectionReasons.push('errors.userNeverApprove');
    }
    if (task.customer.taskAutoApprovalRule === TaskAutoApprovalRule.NEVER_APPROVE) {
        approved = false;
        rejectionReasons.push('errors.customerNeverApprove');
    }
    return {
        approved,
        rejectionReasons
    };
}
export default checkTaskAutomaticApproval;
