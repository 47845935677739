import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import CountrySelectFieldQuery from './graphql/CountrySelectFieldQuery.gql';
import { CountrySelectFieldQuery as CountrySelectFieldQueryType } from './graphql/.generated/CountrySelectFieldQuery';
import ComboboxField from '../ComboboxField';
import FieldLoading from '../FieldLoading';
import languages from '~/constants/languages';
import useUser from '~/contexts/User/useUser';
import { withErrorBoundary } from '../../ErrorBoundary';
import { ComboboxOption } from '~/shadcn/ui/combobox';
import FlagComponent from '../FlagComponent';

type CountrySelectFieldProps = {
  name: string;
  label: string;
  description?: string;
  required?: boolean;
};

function CountrySelectField({ name, label, description, required }: CountrySelectFieldProps) {
  const { language } = useUser();
  const { loading, error: loadError, data } = useQuery<CountrySelectFieldQueryType>(CountrySelectFieldQuery);

  const options = useMemo<ComboboxOption[]>(() => {
    const sortedCountries =
      data?.countries.slice().sort((a, b) => {
        if (a.id === '60' && b.id !== '60') {
          return -1;
        }
        if (a.id !== '60' && b.id === '60') {
          return 1;
        }
        return 0;
      }) ?? [];

    return sortedCountries.map((country) => {
      const countryName = language === languages.DA ? country.name_da : country.name_en;

      return {
        value: country.id,
        label: (
          <span className="tw-flex tw-items-center tw-gap-2">
            <FlagComponent country={country.code} countryName={countryName} />
            {countryName}
          </span>
        )
      };
    });
  }, [data?.countries, language]);

  const optionsMap = useMemo(
    () =>
      (data?.countries ?? []).reduce<Record<string, string>>((acc, country) => {
        const countryName = language === languages.DA ? country.name_da : country.name_en;
        acc[country.id] = countryName;
        return acc;
      }, {}),
    [data?.countries, language]
  );

  if (loading) return <FieldLoading />;
  if (loadError) throw new Error('There was an error loading this component', loadError);

  return (
    <ComboboxField
      name={name}
      label={label}
      description={description}
      required={required}
      options={options}
      filter={(value, search) => (optionsMap[value]?.toLowerCase().includes(search.toLowerCase()) ? 1 : 0)}
    />
  );
}

export default withErrorBoundary(CountrySelectField);
