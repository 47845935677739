import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Top from './Top';
import Footer from './Footer';
import Sidebar from './Sidebar';
import ErrorBoundary from '~/features/shared/components/ErrorBoundary';
import AccountSetupModal from '~/features/account/components/AccountSetupModal';
import ToastBar from './ToastBar';
import useSidebar from '~/contexts/Sidebar/useSidebar';
import { HEADER_HEIGHT } from '~/features/shared/constants';
import { cn } from '~/shadcn/utils';
import ContentLoader from './ContentLoader';
import QuickLinksButton from './QuickLinksButton';

function Layout({ children }: React.PropsWithChildren): JSX.Element {
  const { toggleMenu, isSidebarOpen, isFocusMode } = useSidebar();
  const router = useRouter();

  return (
    <div className="layout">
      <ErrorBoundary>
        {!isFocusMode && !router.pathname.startsWith('/account-setup') && (
          <Sidebar open={!isFocusMode && isSidebarOpen} />
        )}
      </ErrorBoundary>
      <main className={classNames('content', { 'sidebar-open': !isFocusMode && isSidebarOpen })}>
        <ErrorBoundary>
          <Top toggleSidebar={toggleMenu} />
        </ErrorBoundary>
        <div
          className={cn('container-fluid tw-relative [&:not(:has(.alert))]:tw-flex tw-flex-grow tw-group')}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
          <ContentLoader />
        </div>
        <AccountSetupModal />
        <ToastBar />
        <Footer />
        {!isFocusMode && <QuickLinksButton isSidebarOpen={!isFocusMode && isSidebarOpen} />}
      </main>
      <style jsx>{`
        .content {
          padding-top: ${HEADER_HEIGHT}px;
          display: flex;
          flex-direction: column;
          min-height: 100vh;
        }
        .sidebar-open {
          padding-left: 260px;
        }
        :global(.navbar-custom) {
          ${(isFocusMode || !isSidebarOpen) && 'left: 0 !important'};
          z-index: 1081;
        }
        @media (max-width: 1200px) {
          .content {
            padding-left: 0;
          }
          :global(.navbar-custom) {
            left: 0 !important;
          }
        }
      `}</style>
    </div>
  );
}

export default Layout;
