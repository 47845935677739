import classNames from 'classnames';
import Link from 'next/link';

type ButtonLinkProps = {
  title: string;
  icon?: string | JSX.Element;
  href: string;
  className?: string;
  onClick?: any;
  target?: string;
  disabled?: boolean;
  testId?: string;
};

function ButtonLink({
  title,
  icon,
  href,
  className,
  onClick,
  target,
  disabled,
  testId
}: ButtonLinkProps): JSX.Element {
  return (
    <Link
      href={href}
      className={classNames('btn btn-primary', className, { 'pe-none': disabled, disabled })}
      onClick={onClick}
      target={target}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      data-testid={testId}
    >
      {typeof icon === 'string' ? <i className={icon} /> : icon}
      {title}
    </Link>
  );
}

export default ButtonLink;
