import * as React from 'react';
import { type DialogProps } from '@radix-ui/react-dialog';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { Command as CommandPrimitive } from 'cmdk';

import { cn } from '~/shadcn/utils';
import { Dialog, DialogContent } from '~/shadcn/ui/dialog';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      // Layout
      'tw-flex tw-flex-col tw-overflow-hidden',

      // Size
      'tw-h-full tw-w-full',

      // Border
      'tw-rounded-md',

      // Background
      'tw-bg-popover',

      // Text
      'tw-text-popover-foreground',
      className
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {}

function CommandDialog({ children, ...props }: CommandDialogProps) {
  return (
    <Dialog {...props}>
      <DialogContent className="tw-overflow-hidden tw-p-0">
        <Command
          className={cn(
            // Padding
            '[&_[cmdk-group-heading]]:tw-px-2 [&_[cmdk-group]]:tw-px-2 [&_[cmdk-item]]:tw-px-2 [&_[cmdk-item]]:tw-py-3',

            // Font
            '[&_[cmdk-group-heading]]:tw-font-medium',

            // Text
            '[&_[cmdk-group-heading]]:tw-text-muted-foreground',

            // Height/Width
            '[&_[cmdk-input-wrapper]_svg]:tw-h-5 [&_[cmdk-input-wrapper]_svg]:tw-w-5 [&_[cmdk-input]]:tw-h-12 [&_[cmdk-item]_svg]:tw-h-5 [&_[cmdk-item]_svg]:tw-w-5',

            // Layout
            '[&_[cmdk-group]:not([hidden])_~[cmdk-group]]:tw-pt-0'
          )}
        >
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
}

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <div className="tw-flex tw-items-center tw-border-b tw-px-3" cmdk-input-wrapper="">
    <MagnifyingGlassIcon className="tw-mr-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50" />
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        // Layout
        'tw-flex tw-w-full tw-outline-none',

        // Size
        'tw-h-10 tw-text-sm',

        // Border
        'tw-rounded-md',

        // Background
        'tw-bg-transparent',

        // Padding
        'tw-py-3',

        // Text
        'placeholder:tw-text-muted-foreground',

        // State
        'disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
        className
      )}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('tw-max-h-[300px] tw-overflow-y-auto tw-overflow-x-hidden', className)}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty ref={ref} className="tw-py-6 tw-text-center tw-text-sm" {...props} />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      // Layout
      'tw-overflow-hidden',

      // Padding
      'tw-p-1 [&_[cmdk-group-heading]]:tw-px-2 [&_[cmdk-group-heading]]:tw-py-1.5',

      // Text
      'tw-text-foreground [&_[cmdk-group-heading]]:tw-text-xs [&_[cmdk-group-heading]]:tw-text-muted-foreground',

      // Font
      '[&_[cmdk-group-heading]]:tw-font-medium',
      className
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn('tw--mx-1 tw-h-px tw-bg-border', className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      // Layout
      'tw-relative tw-rounded-sm',

      // Cursor
      'tw-cursor-default',

      // Selection
      'tw-select-none',

      // Flexbox
      'tw-flex tw-items-center',

      // Spacing
      'tw-px-2 tw-py-1.5',

      // Text
      'tw-text-sm',

      // Outline
      'tw-outline-none',

      // State
      "aria-selected:tw-bg-accent aria-selected:tw-text-accent-foreground data-[disabled='true']:tw-pointer-events-none data-[disabled='true']:tw-opacity-50",
      className
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

function CommandShortcut({ className, ...props }: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn('tw-ml-auto tw-text-xs tw-tracking-widest tw-text-muted-foreground', className)}
      {...props}
    />
  );
}
CommandShortcut.displayName = 'CommandShortcut';

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator
};
