import { useTranslation } from 'next-i18next';
import { NotificationFrequency } from '@factofly/database';
import { CalendarMinus2, CalendarRange, MailCheck } from 'lucide-react';
import CountrySelectField from '~/features/shared/components/forms/CountrySelectField';
import TextField from '~/features/shared/components/forms/TextField';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '~/shadcn/ui/dialog';
import BankAccountSection from '../SettingsForm/SalaryTab/BankAccountSection';
import InfoPanel from '~/features/shared/components/InfoPanel';
import SelectField from '~/features/shared/components/forms/SelectField';
import usePaymentInfoSettingsForm, { PaymentInfoSettingsFormProps } from './usePaymentInfoSettingsForm';
import FormWrapper from '~/features/forms/components/FormWrapper';
import SubmitButton from '~/features/shared/components/forms/SubmitButton';
import LoadingComponent from '~/features/shared/components/LoadingComponent';
import FormValidationErrors from '~/features/forms/components/FormValidationErrors';
import FormError from '~/features/forms/components/FormError';
import TaxInfoSection from '../SettingsForm/SalaryTab/TaxInfoSection';
import CardRadioGroup, { CardRadioGroupOption } from '~/features/shared/components/forms/CardRadioGroup';

interface PaymentInfoSettingsModalProps {
  open: boolean;
  onClose: () => void;
  onCompleted?: PaymentInfoSettingsFormProps['onCompleted'];
}

export default function PaymentInfoSettingsModal({
  open,
  onClose,
  onCompleted
}: PaymentInfoSettingsModalProps) {
  const { t } = useTranslation('users');
  const { t: tCommon } = useTranslation('common');

  const { form, isRequired, data, onSubmit, loading, saving, error } = usePaymentInfoSettingsForm({
    onCompleted: (...params) => {
      onClose();
      onCompleted?.(...params);
    }
  });

  const options: CardRadioGroupOption[] = [
    {
      Icon: <MailCheck size={32} />,
      label: t(`emailFrequency.${NotificationFrequency.INSTANTLY}`),
      value: NotificationFrequency.INSTANTLY,
      description: t(`emailFrequencyDescription.${NotificationFrequency.INSTANTLY}`)
    },
    {
      Icon: <CalendarMinus2 size={32} />,
      label: t(`emailFrequency.${NotificationFrequency.DAILY}`),
      value: NotificationFrequency.DAILY,
      description: t(`emailFrequencyDescription.${NotificationFrequency.DAILY}`)
    },
    {
      Icon: <CalendarRange size={32} />,
      label: t(`emailFrequency.${NotificationFrequency.WEEKLY}`),
      value: NotificationFrequency.WEEKLY,
      description: t(`emailFrequencyDescription.${NotificationFrequency.WEEKLY}`)
    }
  ];

  const salaryPaymentTypeOptions = [
    { value: 'ON_PAYMENT', label: t('salaryPaymentType.ON_PAYMENT') },
    { value: 'FIRST_OF_MONTH', label: t('salaryPaymentType.FIRST_OF_MONTH') },
    { value: 'LAST_OF_MONTH', label: t('salaryPaymentType.LAST_OF_MONTH') },
    { value: null, label: t('salaryPaymentType.NONE') }
  ].sort((a, b) => `${a.label}`.localeCompare(b.label));

  const modalContent = (
    <FormWrapper form={form} onSubmit={onSubmit}>
      <div className="tw-flex tw-flex-col tw-gap-8 tw-mb-8">
        <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-3">
          <TextField
            name="addressAddress"
            label={t('labels.address')}
            required={isRequired('address.address')}
          />
          <TextField name="addressCity" label={t('labels.city')} required={isRequired('address.city')} />
          <TextField
            name="addressPostalCode"
            label={t('labels.postalCode')}
            required={isRequired('address.postalCode')}
          />
          <CountrySelectField
            name="addressCountryId"
            label={t('labels.country')}
            required={isRequired('address.countryId')}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-3">
          <TaxInfoSection isRequired={isRequired} />
          <BankAccountSection />
          <InfoPanel>
            {t(`salaryPaymentTypeDescription.${form.watch('salaryPaymentType') ?? 'ON_PAYMENT'}`)}
          </InfoPanel>
          <SelectField
            name="salaryPaymentTypeRequested"
            label={t('labels.salaryPaymentTypeRequested')}
            required={isRequired('address.salaryPaymentTypeRequested')}
            options={salaryPaymentTypeOptions}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-gap-3">
          <p
            className="tw-m-0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('messages.emailSettings') }}
          />
          <CardRadioGroup
            className="sm:tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-5"
            name="notificationFrequency"
            options={options}
          />
          <p
            className="tw-m-0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('messages.emailSettingsNote', { email: data?.email })
            }}
          />
        </div>
      </div>
      <FormValidationErrors />
      {error && <FormError message={error.message} />}
      <SubmitButton title={tCommon('buttons.save')} submitting={saving} fullWidth />
    </FormWrapper>
  );

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        if (!value) onClose();
      }}
    >
      <DialogContent className="!tw-max-w-4xl">
        <DialogHeader>
          <DialogTitle>{t('headers.editProfile')}</DialogTitle>
        </DialogHeader>
        {loading ? <LoadingComponent extraPadding /> : modalContent}
      </DialogContent>
    </Dialog>
  );
}
