import { useFormContext } from 'react-hook-form';
import RequiredSymbol from './RequiredSymbol';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/shadcn/ui/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/shadcn/ui/select';
import InfoTooltip from './InfoTooltip';

type SelectFieldProps = {
  name: string;
  label: string | JSX.Element;
  description?: string;
  tooltip?: string;
  required?: boolean;
  placeholder?: string;
  options: SelectFieldOption[];
  disabled?: boolean;
};

export type SelectFieldOption = {
  value: string | number | bigint | null;
  label: string | JSX.Element;
};

function SelectField({
  name,
  label,
  description,
  tooltip,
  options,
  required,
  placeholder,
  disabled
}: SelectFieldProps) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const isInvalid = Boolean(errors[name]);

  return (
    <FormField
      control={control}
      name={name}
      aria-invalid={isInvalid}
      render={({ field }) => (
        <FormItem>
          <FormLabel htmlFor={name} className="tw-flex tw-items-center tw-gap-1 tw-h-5">
            {label} {required && <RequiredSymbol />}
            {tooltip && <InfoTooltip text={tooltip} />}
          </FormLabel>
          <Select
            onValueChange={field.onChange}
            defaultValue={typeof field.value === 'string' ? field.value : field.value?.toString()}
            disabled={disabled}
          >
            <FormControl>
              <SelectTrigger className="!tw-mt-1" invalid={isInvalid} name={name}>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options
                .filter((option) => Boolean(option.value))
                .map((option) => (
                  <SelectItem
                    key={option.value}
                    value={typeof option.value === 'string' ? option.value : option.value!.toString()}
                  >
                    {option.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export default SelectField;
