import { CircleCheck, CircleX, Info, TriangleAlert } from 'lucide-react';
import { PropsWithChildren } from 'react';
import { cn } from '~/shadcn/utils';

type InfoPanelVariant = 'info' | 'warning' | 'error' | 'success';

export interface InfoPanelProps extends PropsWithChildren {
  className?: string;
  id?: string;
  showIcon?: boolean;
  variant?: InfoPanelVariant;
  title?: string;
  testId?: string;
}

const variantTheme = {
  info: {
    styles: 'tw-bg-blue-100 tw-text-blue-950',
    icon: Info
  },
  warning: {
    styles: 'tw-bg-yellow-100 tw-text-yellow-800',
    icon: TriangleAlert
  },
  error: { styles: 'tw-bg-red-100 tw-text-red-600', icon: CircleX },
  success: {
    styles: 'tw-bg-green-100 tw-text-green-900',
    icon: CircleCheck
  }
};

export default function InfoPanel({
  id,
  className,
  children,
  showIcon,
  title,
  variant = 'info',
  testId
}: InfoPanelProps): JSX.Element {
  const Icon = variantTheme[variant].icon;

  return (
    <div
      id={id}
      className={cn(
        'tw-p-6 tw-rounded-lg',
        variantTheme[variant].styles,
        { 'tw-flex tw-gap-4': showIcon },
        className
      )}
      role="alert"
      data-testid={testId || 'info-panel'}
    >
      {showIcon && (
        <div className="tw-w-min">
          <Icon size="1.5em" />
        </div>
      )}
      <div className="tw-flex tw-flex-col tw-gap-1">
        {title && <span className="tw-font-bold">{title}</span>}
        {children}
      </div>
    </div>
  );
}
