import { createContext } from 'react';

export interface ForceParams {
  force?: boolean;
}

export interface FocusModeState {
  value: boolean;
  options?: {
    public?: boolean;
    hideBackButton?: boolean;
    disableButtons?: boolean;
  };
}

export interface SidebarContextState {
  closeMenu: (params?: ForceParams) => void;
  closeOnKeyPress: (event: any) => void;
  toggleMenu: (params?: ForceParams) => void;
  setFocusMode: (value: FocusModeState['value'], options?: FocusModeState['options']) => void;
  setFocusModeOptions: (options: FocusModeState['options']) => void;
  onFocusBack: () => void;
  setFocusBackHandler: (handler: (() => void) | null) => void;
  onFocusClose: () => void;
  setFocusCloseHandler: (handler: (() => void) | null) => void;
  isFocusMode: boolean;
  focusModeOptions: FocusModeState['options'];
  isSidebarOpen: boolean;
  sidebarMiniMode: boolean;
}

export default createContext<SidebarContextState | null>(null);
