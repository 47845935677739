import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';
import ButtonGroupTextField from '~/features/shared/components/forms/ButtonGroupTextField';
import TextField from '~/features/shared/components/forms/TextField';

export default function BankAccountSection() {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation('users');

  const canUpdateTaxInfo = watch('canUpdateTaxInfo');

  return (
    <>
      <TextField name="bankName" label={t('labels.bankName')} />
      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-3">
        <ButtonGroupTextField
          label={t('labels.bankRegistration')}
          name="bankRegistration"
          editable={canUpdateTaxInfo}
          hiddenValue={watch('bankRegistrationHidden')}
          onEditClick={() => setValue('bankRegistration', '')}
        />
        <ButtonGroupTextField
          label={t('labels.bankAccount')}
          name="bankAccount"
          editable={canUpdateTaxInfo}
          hiddenValue={watch('bankAccountHidden')}
          onEditClick={() => setValue('bankAccount', '')}
        />
      </div>
    </>
  );
}
