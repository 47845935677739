import React, { Component } from 'react';
import * as Sentry from '@sentry/nextjs';
import { logger } from '@factofly/logging';
import { withTranslation } from 'next-i18next';
import { TFunction } from 'i18next';
import clientConfig from '~/clientConfig';

interface Props {
  children: React.ReactNode;
  t: TFunction;
}

interface State {
  error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  componentDidCatch(error: Error): void {
    Sentry.captureException(error);
    logger.error(error);
  }

  render(): React.ReactNode {
    const { state, props } = this;

    if (state.error) {
      return (
        <div className="alert alert-danger mb-2">
          <p>{String(props.t('errors.general.title'))}</p>
          <p>
            {String(props.t('errors.general.contactSupport'))}{' '}
            <a href={`mailto:${clientConfig.supportEmail}`} target="_blank" rel="noreferrer">
              {clientConfig.supportEmail}
            </a>{' '}
            {String(props.t('linkingWords.or'))}{' '}
            <a href={`tel:${clientConfig.supportPhone}`}>{clientConfig.supportPhone}</a>
          </p>
        </div>
      );
    }

    return props.children;
  }
}

export default withTranslation('common')(ErrorBoundary);
