/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { Fragment, useRef, useState } from 'react';
import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import { Overlay } from 'react-bootstrap';
import { MOBILE_BREAKPOINT } from '../constants';
import useWindowDimensions from '../hooks/useWindowDimensions';

type InfoTooltipProps = {
  text: string;
  position?: TooltipProps['placement'];
  className?: string;
  icon?: string;
};

const PAGE_PADDING = 24;

export default function InfoTooltip({
  text,
  position,
  className = undefined,
  icon = 'mdi mdi-information'
}: InfoTooltipProps) {
  const [show, setShow] = useState(false);
  const iconRef = useRef<HTMLElement | null>(null);
  const { width } = useWindowDimensions();

  const renderTooltip = (props: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip id="button-tooltip" {...props}>
      <div className="tooltip-body-container">
        {text.split('\n').map((line) => (
          <Fragment key={line}>
            {line}
            <br />
          </Fragment>
        ))}
      </div>
      <button type="button" className="close-button" aria-label="Close" onClick={() => setShow(false)}>
        <i className="mdi mdi-close" />
      </button>
    </Tooltip>
  );

  const adjustTooltipPosition = (props: any) => {
    if (props?.style?.transform && width <= MOBILE_BREAKPOINT) {
      const [transformX] = props.style.transform.match(/\d+\.?\d*/g) || [0];
      const diff = Number(transformX) - PAGE_PADDING;
      const [arrowTransformX] = props.arrowProps?.style?.transform?.match(/\d+\.?\d*/g) || [0];
      const transform = props.style.transform.replace(/\d+\.?\d*/, `${PAGE_PADDING}`);
      const arrowTransform = props.arrowProps.style.transform.replace(
        /\d+\.?\d*/,
        `${Number(arrowTransformX) + diff}`
      );
      return {
        ...props,
        style: { ...props.style, transform },
        arrowProps: {
          ...props.arrowProps,
          style: { ...props.arrowProps.style, transform: arrowTransform }
        }
      };
    }
    return props;
  };

  return (
    <>
      <i
        ref={iconRef}
        role="tooltip"
        onClick={() => width <= MOBILE_BREAKPOINT && setShow(true)}
        onMouseEnter={() => width > MOBILE_BREAKPOINT && setShow(true)}
        onMouseLeave={() => width > MOBILE_BREAKPOINT && setShow(false)}
        className={classNames('fs-4 text-muted ms-1', icon, className)}
      />
      <Overlay placement={position ?? 'bottom'} target={iconRef.current} show={show}>
        {(props) => renderTooltip(adjustTooltipPosition(props))}
      </Overlay>
      <style jsx>{`
        i {
          position: absolute;
          margin-top: -4px;
        }
        :global(#button-tooltip) {
          z-index: 1100;
        }
        :global(.close-button) {
          display: none;
        }
        :global(.tooltip.show) {
          opacity: 1;
        }
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          :global(.tooltip-inner) {
            display: flex;
            align-items: start;
            width: 100%;
            max-width: unset;
          }
          :global(.mdi-close:before) {
            color: #f1f3fa;
          }
          :global(.close-button) {
            display: block;
            border: none;
            background-color: transparent;
          }
          :global(.tooltip) {
            max-width: calc(100vw - ${PAGE_PADDING * 2}px);
          }
        }
      `}</style>
    </>
  );
}
