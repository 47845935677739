export const MOBILE_BREAKPOINT = 576;

export const MEDIUM_BREAKPOINT = 768;

export const LARGE_BREAKPOINT = 992;

export const FOOTER_HEIGHT = 100;

/**
 * Height of the header in pixels (including border)
 */
export const HEADER_HEIGHT = 71;
