import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useUser from '~/contexts/User/useUser';
import FlagComponent from '~/features/shared/components/forms/FlagComponent';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~/shadcn/ui/select';
import LanguageSelectUpdateMutation from './graphql/LanguageSelectUpdateMutation.gql';
import { LanguageSelectUpdateMutation as LanguageSelectUpdateMutationType } from './graphql/.generated/LanguageSelectUpdateMutation';
import useApp from '~/contexts/App/useApp';

const options = [
  {
    value: 'DA',
    label: (
      <span className="tw-flex tw-items-center tw-gap-2">
        <FlagComponent country="DK" countryName="Dansk" />
        Dansk
      </span>
    )
  },
  {
    value: 'EN',
    label: (
      <span className="tw-flex tw-items-center tw-gap-2">
        <FlagComponent country="GB" countryName="English" />
        English
      </span>
    )
  }
];

export default function LanguageSelect() {
  const { language, id } = useUser();
  const { t } = useTranslation('common');
  const router = useRouter();
  const { startContentLoading, finishContentLoading } = useApp();
  const [updateLanguage] = useMutation<LanguageSelectUpdateMutationType>(LanguageSelectUpdateMutation, {
    refetchQueries: ['GetLoggedInUser']
  });

  const onChange = (value: string) => {
    startContentLoading(t('messages.updatingLanguage'));
    updateLanguage({
      variables: { input: { language: value }, where: { id } },
      onError: () => finishContentLoading(),
      awaitRefetchQueries: true
    });
  };

  useEffect(() => {
    finishContentLoading();
    return () => finishContentLoading();
  }, [finishContentLoading, router.locale]);

  return (
    <Select onValueChange={onChange} defaultValue={language}>
      <SelectTrigger className="tw-flex !tw-w-fit tw-h-fit tw-gap-2 !tw-p-2">
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="!tw-z-topBarSelect">
        {options
          .filter((option) => Boolean(option.value))
          .map((option) => (
            <SelectItem key={option.value} value={option.value}>
              {option.label}
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
}
