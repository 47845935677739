import { ChevronDown, Plus } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useState } from 'react';
import { Button } from '~/shadcn/ui/button';
import cn from '~/shadcn/utils';

interface QuickLinksButtonProps {
  isSidebarOpen: boolean;
}

export default function QuickLinksButton({ isSidebarOpen }: QuickLinksButtonProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('common');

  const options = [
    { id: 'new-task', title: t('buttons.quickLinks.newTask'), href: '/tasks/new' },
    { id: 'new-customer', title: t('buttons.quickLinks.newCustomer'), href: '/customers/new' },
    { id: 'new-invoice', title: t('buttons.quickLinks.newInvoice'), href: '/invoices/new' }
  ];

  const backdrop = (
    <div
      className={cn(
        'quick-links-gradient quick-links-transition',
        'tw-z-floatingButton tw-fixed tw-left-0 tw-bottom-0 tw-h-[500px] tw-w-[500px] tw-pointer-events-none tw',
        { 'sm:tw-left-[260px]': isSidebarOpen }
      )}
    />
  );

  return (
    <>
      <ul
        className={cn(
          'quick-links-transition quick-links-list',

          // Positioning
          'tw-z-floatingButton tw-fixed tw-left-5 tw-bottom-[84px]',

          // Margin and Padding
          'tw-m-0 tw-p-0',

          // Flexbox Layout
          'tw-flex tw-flex-col tw-gap-2.5',
          {
            'tw-scale-0 tw-opacity-0': !open,
            'tw-opacity-1': open,
            'sm:tw-left-[280px]': isSidebarOpen
          }
        )}
      >
        {options.map((option) => (
          <li key={option.id}>
            <Button
              className="tw-flex tw-gap-2.5 !tw-px-3 !tw-py-2.5 hover:!tw-bg-primary hover:tw-text-primary-foreground"
              onClick={() => setOpen(false)}
              asChild
            >
              <Link href={option.href}>
                <Plus size="1.25em" />
                {option.title}
              </Link>
            </Button>
          </li>
        ))}
      </ul>
      <Button
        className={cn(
          'quick-links-transition',
          // Positioning
          'tw-z-floatingButton tw-fixed tw-left-5 tw-bottom-5',

          // Flexbox Layout
          'tw-flex tw-items-center tw-justify-center',

          // Sizing
          'tw-h-12 tw-w-12 !tw-p-0',

          // Border Radius
          '!tw-rounded-full',

          // Hover Effects
          'hover:tw-scale-110 hover:!tw-bg-primary',
          {
            'sm:tw-left-[280px]': isSidebarOpen,
            'tw-scale-110': open
          }
        )}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <div className="tw-relative tw-w-full tw-h-full">
          <Plus
            size={24}
            className={cn('quick-links-plus tw-left-1/4 tw-top-1/4 -tw-translate-x-1/2 -tw-translate-y-1/2', {
              open,
              closed: !open
            })}
          />
          <ChevronDown
            size={24}
            className={cn(
              'quick-links-chevron tw-left-1/4 tw-top-1/4 -tw-translate-x-1/2 -tw-translate-y-1/2',
              {
                open,
                closed: !open
              }
            )}
          />
        </div>
      </Button>
      {open && backdrop}
      <style jsx>{`
        :global(.quick-links-chevron) {
          user-select: none;
          position: absolute;
          transition: transform 0.16s linear, opacity 0.08s linear;
        }
        :global(.quick-links-chevron.open) {
          opacity: 1;
          transform: rotate(0deg);
        }
        :global(.quick-links-chevron.closed) {
          opacity: 0;
          transform: rotate(-60deg);
        }
        :global(.quick-links-plus) {
          user-select: none;
          position: absolute;
          transition: transform 0.16s linear, opacity 0.08s linear;
        }
        :global(.quick-links-plus.open) {
          opacity: 0;
          transform: rotate(30deg) scale(0);
        }
        :global(.quick-links-plus.closed) {
          opacity: 1;
          transform: rotate(0deg) scale(1);
        }
        :global(.quick-links-transition) {
          transition: left 0.5s, transform 300ms cubic-bezier(0, 1.2, 1, 1), opacity 83ms ease-out;
        }
        :global(.quick-links-gradient) {
          background: radial-gradient(at left bottom, rgba(29, 39, 54, 0.16) 0%, rgba(29, 39, 54, 0) 72%);
        }
        :global(.quick-links-list) {
          transform-origin: bottom left;
          transition: width 200ms, height 200ms, max-height 200ms, transform 300ms cubic-bezier(0, 1.2, 1, 1),
            opacity 83ms ease-out;
        }
      `}</style>
    </>
  );
}
