import { PayrollLineType } from './PayrollLineType';
import { dateOnly, firstDayOfMonth, lastDayOfMonth, lastWorkingDayOfMonth } from '@factofly/dates';
import Decimal from 'decimal.js';
import calculateHourlyRate from './calculateHourlyRate';
const VACATION_PAYMENT_TITLE = 'Overført til feriekonto';
const PREVIOUS_PAYMENT_TITLE = 'Allerede udbetalt løn';
const VACATION_PAYMENT_RATE = 0.125;
const VAT_RATE = 0.25;
var SalaryCalculationVersion;
(function (SalaryCalculationVersion) {
    SalaryCalculationVersion[SalaryCalculationVersion["VERSION_3_10"] = 0] = "VERSION_3_10";
    SalaryCalculationVersion[SalaryCalculationVersion["VERSION_3_11"] = 1] = "VERSION_3_11";
})(SalaryCalculationVersion || (SalaryCalculationVersion = {}));
export function calculatePayroll(salary, debug = false) {
    const previousPayment = salary.previousSalaries.reduce((prev, current) => prev + Number(current.paymentAmount ?? 0), 0);
    let calculationVersion = SalaryCalculationVersion.VERSION_3_11;
    // check if any payments were made before the 16th of May 2024. If so, then use the old calculation version
    const version3_11Date = new Date('2024-05-19');
    if (salary.paymentDate < version3_11Date ||
        salary.previousSalaries.some((x) => x.paymentDate < version3_11Date)) {
        calculationVersion = SalaryCalculationVersion.VERSION_3_10;
    }
    const previousInvoices = salary.previousSalaries.map((x) => x.invoices).flat();
    const allInvoices = [...salary.invoices, ...previousInvoices];
    const allDeductions = [...salary.deductions, ...salary.previousDeductions];
    const allMileages = [...salary.mileages, ...salary.previousMileages];
    const hours = allInvoices.reduce((prev, current) => prev + Number(current.hoursWorked), 0);
    const invoiceTotalDkkExVAT = allInvoices.reduce((prev, current) => prev +
        (current.paidAmountDkk
            ? // the total amount that is paid out is the amount without VAT, as the VAT is paid out separately
                current.paidAmountDkk / (current.includeVat ? 1 + VAT_RATE : 1)
            : current.subtotalDkk), 0);
    const allowanceTotalDkk = allInvoices.reduce((prev, current) => prev.add(current.allowanceDkk ?? 0), new Decimal(0));
    const invoiceTotalForVacationPaymentDkk = allInvoices
        .filter((x) => !x.customer?.company || x.customer?.company?.vacationPayment)
        .reduce((prev, { paidAmountDkk, includeVat, subtotalDkk }) => {
        let invoiceTotalDkk = paidAmountDkk
            ? // the total amount that is paid out is the amount without VAT, as the VAT is paid out separately
                paidAmountDkk / (includeVat ? 1 + VAT_RATE : 1)
            : subtotalDkk;
        return prev + invoiceTotalDkk;
    }, 0);
    const feeTotalDkk = allInvoices.reduce((prev, current) => prev + current.discountedFeeAmountDkk, 0);
    let payrollLines = [];
    // previously paid salaries
    if (previousPayment > 0) {
        const details = {
            name: PREVIOUS_PAYMENT_TITLE,
            quantity: 1,
            type: PayrollLineType.DeductionAfterTax,
            paymentPerUnit: previousPayment
        };
        payrollLines.push(details);
    }
    // fee amount
    if (calculationVersion === SalaryCalculationVersion.VERSION_3_10) {
        payrollLines = payrollLines.concat(salary.invoices.map((invoice) => ({
            name: `Factofly #${invoice.invoiceNumber}`,
            quantity: 1,
            type: PayrollLineType.GrossSalaryWithHolidayPayReduction,
            paymentPerUnit: invoice.discountedFeeAmountDkk
        })));
    }
    // deductions without VAT
    if (calculationVersion === SalaryCalculationVersion.VERSION_3_10) {
        payrollLines = payrollLines.concat(salary.deductions.map((deduction) => ({
            name: (deduction.description ?? '').substring(0, 50) + // zenegy only accepts 128 characters
                ` #${deduction.id}`,
            quantity: 1,
            type: PayrollLineType.GrossSalaryWithoutHolidayPayReduction,
            paymentPerUnit: (deduction.totalDkk ?? 0) / (deduction.includeVat ? 1.25 : 1)
        })));
    }
    // deductions with VAT
    payrollLines = payrollLines.concat(salary.deductions.map((deduction) => ({
        name: (deduction.description ?? '').substring(0, 50) + // zenegy only accepts 128 characters
            ` #${deduction.id}`,
        quantity: 1,
        type: PayrollLineType.Expenses,
        paymentPerUnit: deduction.totalDkk
    })));
    salary.mileages.forEach((mileage) => {
        payrollLines.push({
            name: `Kørsel #${mileage.id}`,
            quantity: mileage.distanceKm.toNumber(),
            type: PayrollLineType.Mileage,
            paymentPerUnit: mileage.rate.toNumber()
        });
    });
    const deductionTotalDkkExVat = new Decimal(allDeductions.reduce((prev, current) => prev + current.totalDkk / (current.includeVat ? 1.25 : 1), 0));
    const mileageTotalDkk = new Decimal(allMileages.reduce((prev, current) => prev + (current.totalDkk ?? new Decimal(0)).toNumber(), 0));
    let hourlyRate = calculateHourlyRate({
        hours: new Decimal(hours),
        paymentAmountDkkExVat: new Decimal(invoiceTotalDkkExVAT),
        allowanceDkk: new Decimal(allowanceTotalDkk),
        feeAmountDkk: new Decimal(feeTotalDkk),
        mileageTotalDkk,
        deductionTotalDkkExVat
    });
    if (calculationVersion === SalaryCalculationVersion.VERSION_3_10) {
        hourlyRate = new Decimal((invoiceTotalDkkExVAT - mileageTotalDkk.toNumber()) / hours);
    }
    // vacation Payment
    if (salary.user.vacationPayment && invoiceTotalForVacationPaymentDkk > 0) {
        const vacationPaymentHourlyRate = calculateHourlyRate({
            hours: new Decimal(hours),
            paymentAmountDkkExVat: new Decimal(invoiceTotalForVacationPaymentDkk),
            allowanceDkk: new Decimal(allowanceTotalDkk),
            feeAmountDkk: new Decimal(feeTotalDkk),
            mileageTotalDkk,
            deductionTotalDkkExVat
        });
        let vacationPaymentDkk = vacationPaymentHourlyRate.times(hours).times(VACATION_PAYMENT_RATE).toNumber();
        if (calculationVersion === SalaryCalculationVersion.VERSION_3_10) {
            vacationPaymentDkk =
                (invoiceTotalForVacationPaymentDkk - feeTotalDkk - mileageTotalDkk.toNumber()) *
                    VACATION_PAYMENT_RATE;
        }
        const details = {
            name: VACATION_PAYMENT_TITLE,
            quantity: 1,
            type: PayrollLineType.GrossSalaryWithoutHolidayPayReduction,
            paymentPerUnit: vacationPaymentDkk
        };
        payrollLines.push(details);
    }
    const firstDay = dateOnly(firstDayOfMonth(salary.paymentDate));
    const lastDay = dateOnly(lastDayOfMonth(salary.paymentDate));
    const lastWorkingDay = dateOnly(lastWorkingDayOfMonth(salary.paymentDate));
    if (hourlyRate.lessThanOrEqualTo(0)) {
        throw new Error('Salary hourly rate is 0 or less');
    }
    const payrollDetails = {
        periodFrom: firstDay,
        periodTo: lastDay,
        dispositionDate: lastWorkingDay,
        employee: {
            email: salary.user.email,
            hours,
            rateDkk: hourlyRate.toNumber()
        },
        payrollLines: payrollLines
    };
    if (debug) {
        payrollDetails.debug = {
            hours: new Decimal(hours).toNumber(),
            paymentAmountDkkExVat: new Decimal(invoiceTotalDkkExVAT).toNumber(),
            allowanceDkk: new Decimal(allowanceTotalDkk).toNumber(),
            feeAmountDkk: new Decimal(feeTotalDkk).toNumber(),
            mileageTotalDkk: mileageTotalDkk.toNumber(),
            deductionTotalDkkExVat: deductionTotalDkkExVat.toNumber()
        };
    }
    return payrollDetails;
}
export default calculatePayroll;
