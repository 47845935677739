import Decimal from 'decimal.js';
import DiscountType from './DiscountType';
/**
 * Calculates the discount for the invoice fee. Percentages are given from 0 to 1.
 * @param total Total amount of the invoice
 * @param discountType Type of discount
 * @param discountValue Value of the discount
 * @param maxDiscount Maximum discount value
 * @returns Percentage discount rate from 0-1
 */
export function calculateDiscountRate(total, discountType, discountValue, maxDiscount) {
    if (discountType === DiscountType.PERCENTAGE) {
        return Decimal.max(Decimal.min(total.times(discountValue.dividedBy(100)), maxDiscount, total), 0).dividedBy(total);
    }
    if (discountType === DiscountType.FIXED) {
        return Decimal.max(Decimal.min(discountValue, total), 0).dividedBy(total);
    }
    return 0;
}
