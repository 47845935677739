import dynamic from 'next/dynamic';
import * as animationData from './logo_loader.json';

const Lottie = dynamic(() => import('react-lottie'), { ssr: false });

interface LoaderProps {
  width?: number;
}

function Loader({ width }: LoaderProps) {
  return (
    <Lottie
      ariaRole="alert"
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid meet'
        }
      }}
      height="fit-content"
      width={width ?? 200}
    />
  );
}

export default Loader;
