import { forwardRef } from 'react';
import { cn } from '~/shadcn/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, invalid, ...props }, ref) => (
  <input
    type={type}
    className={cn(
      // Layout
      'tw-flex tw-h-12 tw-w-full tw-px-3 tw-py-1 tw-rounded-sm',

      // Border
      'tw-border tw-border-input-border file:tw-border-0 enabled:hover:tw-border-input-border-hover',

      // Background
      'tw-bg-white file:tw-bg-transparent disabled:tw-bg-input-disabled',

      // Text
      'tw-text-sm file:tw-text-sm file:tw-font-medium placeholder:tw-text-input-text-placeholder-foreground',

      // Transition
      'tw-transition-colors',

      // Outline
      'focus-visible:tw-outline-none',

      // Focus
      'focus-visible:tw-ring-input-ring focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2',

      // Read-only
      'read-only:tw-bg-input-disabled read-only:tw-ring-0 read-only:tw-ring-offset-0',

      // Disabled
      'disabled:tw-cursor-not-allowed disabled:tw-text-input-text-disabled disabled:tw-bg-input-background-disabled',
      {
        'enabled:tw-border-input-border-invalid enabled:hover:tw-border-input-border-invalid-hover': invalid
      },
      className
    )}
    ref={ref}
    {...props}
  />
));

Input.displayName = 'Input';

export { Input };
