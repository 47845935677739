import '../styles/hyper/icons.scss';
import '../styles/globals.scss';

import type { AppProps } from 'next/app';
import { UserProvider as Auth0UserProvider } from '@auth0/nextjs-auth0/client';
import { ApolloProvider } from '@apollo/client';
import 'intl-pluralrules';
import { appWithTranslation } from 'next-i18next';
import { ComponentType } from 'react';
import Head from 'next/head';
import DefaultLayout from '~/layouts/DefaultLayout';
import RedirectToLogin from '~/features/shared/components/RedirectToLogin';
import UserContextProvider from '~/contexts/User/UserContextProvider';
import { useApollo } from '~/utils/apolloClient';
import GoogleTagManager from '~/features/shared/components/GoogleTagManager';
import { MyAppProps } from './Next';
import PostHogProvider from '~/features/analytics/components/PostHogProvider';
import SidebarContextProvider from '~/contexts/Sidebar/SidebarContextProvider';
import AppContextProvider from '~/contexts/App/AppContextProvider';

export function MyApp({ Component, pageProps, router }: MyAppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  if (Component.getLayout) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return Component.getLayout(<Component {...pageProps} />);
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      <Auth0UserProvider>
        <PostHogProvider>
          <RedirectToLogin router={router}>
            <ApolloProvider client={apolloClient}>
              <UserContextProvider>
                <SidebarContextProvider>
                  <AppContextProvider>
                    <GoogleTagManager />
                    <DefaultLayout>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <Component {...pageProps} />
                    </DefaultLayout>
                  </AppContextProvider>
                </SidebarContextProvider>
              </UserContextProvider>
            </ApolloProvider>
          </RedirectToLogin>
        </PostHogProvider>
      </Auth0UserProvider>
    </>
  );
}

export default appWithTranslation(MyApp as ComponentType<AppProps>);
