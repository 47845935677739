import { useCallback, useState } from 'react';
import { Check, ChevronDown } from 'lucide-react';

import { cn } from '~/shadcn/utils';
import { Button } from '~/shadcn/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '~/shadcn/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/shadcn/ui/popover';

export interface ComboboxOption {
  value: string;
  label: string | JSX.Element;
}

export interface ComboboxProps {
  options: ComboboxOption[];
  value: ComboboxOption['value'] | undefined;
  onChange: (option: ComboboxOption) => void;
  className?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  emptyMessage?: string;
  disabled?: boolean;
  filter?: (value: string, search: string) => 1 | 0;
  name?: string;
  invalid?: boolean;
}

export function Combobox({
  className,
  options,
  placeholder,
  searchPlaceholder,
  emptyMessage,
  disabled,
  value,
  onChange,
  filter,
  name,
  invalid
}: ComboboxProps) {
  const [open, setOpen] = useState(false);

  const selectedOptionLabel = options.find((option) => option.value === value)?.label;

  const handleSelect = useCallback(
    (option: ComboboxOption) => {
      setOpen(false);
      onChange(option);
    },
    [onChange]
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          role="combobox"
          aria-expanded={open}
          {...(!value && { 'data-placeholder': true })}
          className={cn(
            'tw-group',

            // Layout
            'tw-flex tw-h-12 tw-w-full !tw-px-3 tw-py-2 tw-rounded-sm tw-justify-between',

            // Border
            'tw-border !tw-border-input-border enabled:hover:!tw-border-input-border-hover',

            // Shadow
            '!tw-shadow-none',

            // Background
            'tw-bg-white disabled:tw-bg-input-disabled hover:!tw-bg-white',

            // Text
            'tw-text-sm !tw-text-foreground tw-font-normal data-[placeholder]:tw-text-input-text-placeholder',

            // Transition
            'tw-transition-colors',

            // Outline
            'focus-visible:tw-outline-none',

            // Focused
            'data-[state=open]:tw-ring-input-ring data-[state=open]:tw-ring-2 data-[state=open]:tw-ring-offset-2 focus-visible:tw-ring-input-ring focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2',

            // Read-only
            'read-only:tw-bg-input-disabled read-only:tw-ring-0',

            // Disabled
            'disabled:!tw-cursor-not-allowed disabled:!tw-text-input-text-disabled disabled:!tw-bg-input-background-disabled disabled:tw-opacity-100',
            {
              'enabled:!tw-border-input-border-invalid enabled:hover:!tw-border-input-border-invalid-hover':
                invalid,
              '!tw-justify-end': !selectedOptionLabel && !placeholder
            },
            className
          )}
          disabled={disabled}
          data-disabled={disabled}
          data-testid={`${name ?? 'select'}-trigger`}
          tabIndex={0}
        >
          {value ? selectedOptionLabel : placeholder}
          <ChevronDown className="tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-transition-transform tw-duration-200 group-data-[state='open']:tw-rotate-180 tw-text-neutral-500" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="!tw-p-0 tw-w-[--radix-popover-trigger-width] tw-max-h-[--radix-popover-content-available-height]"
      >
        <Command filter={filter}>
          <CommandInput placeholder={searchPlaceholder} />
          <CommandEmpty>{emptyMessage}</CommandEmpty>
          <CommandList>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  className="tw-gap-2 hover:tw-cursor-pointer"
                  key={option.value}
                  value={option.value}
                  onSelect={() => handleSelect(option)}
                >
                  <span className="tw-w-full tw-text-input-text">{option.label}</span>
                  <Check
                    className={cn(
                      'tw-h-4 tw-w-4',
                      value === option.value ? 'tw-opacity-100' : 'tw-opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
