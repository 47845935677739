import projectPackage from 'package.json';

const isProduction = process.env.NODE_ENV === 'production';

const clientConfig = {
  baseUrl: process.env.NEXT_PUBLIC_BASE_URL!,
  googleTagManagerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID!,
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_ACCESS_KEY!,
  supportEmail: process.env.NEXT_PUBLIC_SUPPORT_EMAIL ?? 'kontakt@factofly.com',
  supportPhone: process.env.NEXT_PUBLIC_SUPPORT_PHONE ?? '+45 71 96 00 54',
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  postHog: {
    publicKey: process.env.NEXT_PUBLIC_POSTHOG_KEY!,
    hostUrl: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? 'https://eu.i.posthog.com',
    overridableUrls: ['localhost:3005', 'app-dev.factofly.com', 'ff-web-app-review.factofly.com']
  },
  rates: {
    taxAmount: 0.25
  },
  version: projectPackage.version,
  i18n: {
    debug: !isProduction
  },
  defaultLocale: 'da-DK',

  sentry: {
    dsn:
      process.env.NEXT_PUBLIC_SENTRY_DSN ??
      'https://693ba925c6004f0aa7441f74d428f03b@o1294262.ingest.sentry.io/6525037',
    tracesSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE ?? 0.1)
  }
};

export default clientConfig;
