import React from 'react';
import { RoleEnum, SalaryPaymentTypeEnum } from '../../.generated/globalTypes';
import { GetLoggedInUser_me_userMileageRequest } from './graphql/.generated/GetLoggedInUser';

export interface IUserContext {
  firstName?: string | null;
  /**
   * User last name
   */
  lastName?: string | null;
  /**
   * Users displayable name, using first name, last name or email to create this
   */
  displayName?: string;
  /**
   * User email
   */
  email?: string;
  phoneNumber?: string;
  role?: RoleEnum;
  language?: string;
  referral?: string;
  locale?: string;
  jobTypeId?: bigint;
  referralLinkCode?: string;
  latestSentInvoiceDate?: Date;
  lastSentTaskDate?: Date;
  /**
   * Unique identifier for this type
   */
  salaryPaymentType?: SalaryPaymentTypeEnum | null;
  id?: any;
  features?: any;
  availableCredits?: number;
  accountSetupComplete?: boolean;
  isUser?: boolean;
  isAdmin?: boolean;
  emailVerified?: boolean;
  isImpersonating?: boolean;
  originalUser?: { id: number; isAdmin: boolean };
  loading: boolean;
  canUpdateVacationPayment?: boolean | null;
  canAccessMileage?: boolean | null;
  userMileageRequest?: GetLoggedInUser_me_userMileageRequest | null;
  currentYearDistanceKmTotal?: number;
  impersonateUser?: (userId: number) => void;
  clearImpersonatedUser?: () => void;
  canUpdateEmail?: boolean;
  hasCompletedProfile?: boolean;
}

export default React.createContext<IUserContext>({ loading: false });
