import { dateOnly, firstDayOfMonth, lastWorkingDayOfMonth } from '@factofly/dates';
import { SalaryPaymentType } from './SalaryPaymentType';
export const getSalaryDueDate = ({ user: { salaryPaymentType, salaryPaymentDay, salaryPaymentValue }, invoices }, now = new Date()) => {
    if (salaryPaymentType === SalaryPaymentType.VIA_INVOICE)
        return null;
    const lastDay = lastWorkingDayOfMonth(now).getDate();
    let isTimed = salaryPaymentType === SalaryPaymentType.TIMED;
    let dayOfMonth = salaryPaymentDay;
    let isLastOfMonth = false;
    if (salaryPaymentType === SalaryPaymentType.FIRST_OF_MONTH) {
        dayOfMonth = 1;
        isTimed = true;
    }
    if (salaryPaymentType === SalaryPaymentType.LAST_OF_MONTH) {
        dayOfMonth = lastDay;
        isLastOfMonth = true;
        isTimed = true;
    }
    if (isTimed) {
        let paymentDay = dayOfMonth;
        let paymentMonth = now.getUTCMonth();
        if (paymentDay > 30) {
            isLastOfMonth = true;
        }
        if (paymentDay > lastDay) {
            paymentDay = lastDay;
        }
        const today = now.getDate();
        if (paymentDay < today) {
            paymentMonth += 1;
            // we need to check the new last working day of the month
            if (isLastOfMonth) {
                const nextMonthDate = new Date(Date.UTC(now.getUTCFullYear(), paymentMonth, 1));
                const lastWorkingDateNextMonth = lastWorkingDayOfMonth(nextMonthDate);
                paymentDay = lastWorkingDateNextMonth.getDate();
            }
        }
        let paymentDate = new Date(now);
        paymentDate.setDate(paymentDay);
        paymentDate.setMonth(paymentMonth);
        return dateOnly(paymentDate);
    }
    if (salaryPaymentType === SalaryPaymentType.FIRST_OF_MONTH) {
        return dateOnly(firstDayOfMonth(now));
    }
    if (salaryPaymentType === SalaryPaymentType.LAST_OF_MONTH) {
        return dateOnly(lastWorkingDayOfMonth(now));
    }
    if (salaryPaymentType === SalaryPaymentType.ON_PAYMENT ||
        salaryPaymentType === SalaryPaymentType.EARLY ||
        salaryPaymentType == null) {
        return dateOnly(now);
    }
    if (salaryPaymentType === SalaryPaymentType.VALUE && salaryPaymentValue) {
        const invoicePaymentTotal = invoices.reduce((acc, invoice) => acc + (invoice.paidAmountDkk ?? 0), 0);
        if (invoicePaymentTotal >= salaryPaymentValue) {
            return dateOnly(now);
        }
    }
    return null;
};
export default getSalaryDueDate;
