import moment from 'moment';
const AGE_OF_MAJORITY = 18;
export function checkIfCPRIsUnderage(CPR) {
    try {
        if (CPR.length < 10) {
            return false;
        }
        const day = Number.parseInt(CPR.slice(0, 2));
        const month = Number.parseInt(CPR.slice(2, 4));
        let year = Number.parseInt(CPR.slice(4, 6));
        const lastTwoDigitsOfThisYear = Number.parseInt(moment().format('YY'));
        if (year > lastTwoDigitsOfThisYear) {
            // CPR is from the 1900s
            year = 1900 + year;
        }
        else {
            // CPR is from the 2000s
            year = 2000 + year;
        }
        const birthDate = moment(`${year}-${month}-${day}`, 'YYYY-MM-DD');
        if (!birthDate.isValid()) {
            return false;
        }
        const today = moment();
        const age = today.diff(birthDate, 'years');
        return age < AGE_OF_MAJORITY;
    }
    catch (error) {
        console.error(error);
        return false;
    }
}
