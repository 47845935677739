import { Fragment, useState } from 'react';
import { Info } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '~/shadcn/ui/tooltip';
import { cn } from '~/shadcn/utils';
import { Button } from '~/shadcn/ui/button';

type InfoTooltipProps = {
  text: string;
  className?: string;
};

export default function InfoTooltip({ text, className }: InfoTooltipProps) {
  const [open, setOpen] = useState(false);

  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip open={open}>
        <TooltipTrigger asChild>
          <Button
            type="button"
            variant="ghost"
            className={cn('tw-cursor-pointer tw-h-5 !tw-p-1', className)}
            onClick={() => setOpen(!open)}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
          >
            <Info size={16} />
          </Button>
        </TooltipTrigger>
        <TooltipContent collisionPadding={24} className="tw-max-w-[calc(100vw-theme(space.12))]">
          <span className="tw-inline-block">
            {text.split('\n').map((line) => (
              <Fragment key={line}>
                {line}
                <br />
              </Fragment>
            ))}
          </span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
