import { useRef, useState } from 'react';
import { PencilOff, Pencil, Eye, EyeOff } from 'lucide-react';
import { Button } from '~/shadcn/ui/button';
import { Input, InputProps } from '~/shadcn/ui/input';
import { cn } from '~/shadcn/utils';

export interface ButtonGroupInputProps {
  className?: string;
  hiddenValue?: string;
  inputProps?: InputProps;
  viewable?: boolean;
  editable?: boolean;
  onEditClick?: () => void;
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
}

export default function ButtonGroupInput({
  className,
  editable,
  hiddenValue,
  viewable,
  inputProps,
  disabled,
  onEditClick,
  invalid,
  name
}: ButtonGroupInputProps) {
  const [hidden, setHidden] = useState(true);
  const [show, setShow] = useState(!hiddenValue ? viewable || editable : false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={cn(
        'tw-flex tw-w-full tw-items-center',

        // Border
        'tw-rounded-sm',

        // Focus
        'focus-within:tw-ring-input-ring focus-within:tw-ring-2 focus-within:tw-ring-offset-2',
        className
      )}
    >
      <Input
        ref={inputRef}
        {...inputProps}
        className={cn('tw-relative', 'focus-within:!tw-ring-0 focus-within:!tw-ring-offset-0', {
          'tw-rounded-r-none': !show && (viewable || editable),
          'tw-bg-input-background-disabled': !show
        })}
        readOnly={!show}
        invalid={invalid}
        {...(!show && hiddenValue && hidden && { value: hiddenValue })}
      />
      {viewable && !show && (
        <Button
          variant="outline"
          className={cn(
            'tw-border tw-border-input-border tw-bg-white tw-rounded-l-none !tw-shadow-none',
            // Focus
            'focus-visible:!tw-bg-neutral-100 focus-visible:!tw-ring-0 focus-visible:!tw-ring-offset-0',
            {
              '!tw-rounded-none': editable,
              'tw-border-x-0': editable,
              'tw-border-l-0': !editable
            }
          )}
          onClick={(event) => {
            event.preventDefault();
            setHidden((prevState) => !prevState);
          }}
          disabled={disabled}
        >
          {hidden ? (
            <Eye size={16} className="tw-text-muted" />
          ) : (
            <EyeOff size={16} className="tw-text-muted" />
          )}
        </Button>
      )}
      {editable && !show && (
        <Button
          variant="outline"
          className={cn(
            'tw-border tw-border-input-border tw-bg-white tw-rounded-l-none !tw-shadow-none',

            // Focus
            'focus-visible:!tw-bg-neutral-100 focus-visible:!tw-ring-0 focus-visible:!tw-ring-offset-0',
            {
              'tw-border-l-0': !viewable
            }
          )}
          onClick={(event) => {
            event.preventDefault();
            if (!show) inputRef.current?.focus();
            setShow((prevState) => !prevState);
            onEditClick?.();
          }}
          disabled={disabled}
          data-testid={`${name}-edit-button`}
        >
          {!show ? (
            <Pencil size={16} className="tw-text-muted" />
          ) : (
            <PencilOff size={16} className="tw-text-muted" />
          )}
        </Button>
      )}
    </div>
  );
}
