import React from 'react';
import RouterLink from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { ChevronLeft, Menu, X } from 'lucide-react';
import Logo from '~/features/shared/components/Logo';
import Profile from './Profile';
import useUser from '~/contexts/User/useUser';
import useSidebar from '~/contexts/Sidebar/useSidebar';
import { HEADER_HEIGHT } from '~/features/shared/constants';
import { Button } from '~/shadcn/ui/button';
import { cn } from '~/shadcn/utils';
import LanguageSelect from './LanguageSelect/LanguageSelect';

type TopProps = {
  toggleSidebar: () => void;
};

function Top({ toggleSidebar }: TopProps): JSX.Element {
  const { isImpersonating, clearImpersonatedUser } = useUser();
  const { t: tCommon } = useTranslation('common');
  const { isFocusMode, focusModeOptions, onFocusBack, onFocusClose } = useSidebar();

  const focusModeTopBar = (
    <div className="tw-relative tw-flex tw-justify-between tw-items-center tw-w-full tw-px-3 md:tw-px-6">
      <Button
        className={cn('tw-flex tw-gap-2', { 'tw-invisible': focusModeOptions?.hideBackButton })}
        data-testid="topbar-back-button"
        type="button"
        variant="ghost"
        onClick={onFocusBack}
        disabled={focusModeOptions?.disableButtons}
      >
        <ChevronLeft size={20} />
        {tCommon('buttons.back')}
      </Button>
      <div className="tw-absolute tw-flex tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2">
        {focusModeOptions?.public ? (
          <Logo className="tw-relative" width="100%" height="50" />
        ) : (
          <RouterLink href="/" className="tw-flex tw-items-center">
            <Logo className="tw-relative" width="100%" height="50" />
          </RouterLink>
        )}
      </div>
      {focusModeOptions?.public ? (
        <LanguageSelect />
      ) : (
        <Button
          className={cn('tw-flex tw-gap-2', { 'tw-invisible': focusModeOptions?.public })}
          data-testid="topbar-close-button"
          type="button"
          variant="ghost"
          onClick={onFocusClose}
          disabled={focusModeOptions?.disableButtons}
        >
          {tCommon('buttons.close')}
          <X size={20} />
        </Button>
      )}
    </div>
  );

  const regularTopBar = (
    <>
      <div className="tw-flex xl:tw-invisible">
        <button type="button" className="btn btn-white toggle-menu m-1" onClick={toggleSidebar}>
          <Menu />
        </button>
        <RouterLink href="/" legacyBehavior>
          <span className="tw-flex tw-items-center">
            <Logo className="tw-block tw-relative hover:tw-cursor-pointer" height="50" />
          </span>
        </RouterLink>
      </div>
      <div className="tw-flex tw-items-center">
        {isImpersonating && clearImpersonatedUser && (
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={() => {
              clearImpersonatedUser();
            }}
          >
            Stop Impersonating
          </button>
        )}
        <ul className="list-unstyled topbar-menu mb-0">
          <li className="dropdown notification-list">
            <Profile />
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <div
      className={classNames('topbar navbar-custom tw-flex tw-border-b tw-border-neutral-200', {
        'tw-justify-between': !isFocusMode
      })}
    >
      {isFocusMode ? focusModeTopBar : regularTopBar}
      <style jsx>{`
        .topbar {
          padding-left: calc(100vw - 100%);
        }
        .topbar :global(.logo) {
          display: inline-block;
          line-height: ${HEADER_HEIGHT}px;
          width: 125px;
        }
        .topbar :global(.btn.cancel-impersonation) {
          margin-top: 18px;
        }
        .topbar :global(.btn.toggle-menu:focus) {
          box-shadow: none;
        }
        .topbar :global(.btn.toggle-menu i) {
          font-size: 1.5rem;
          margin: 0;
          padding: 0;
        }
        .topbar :global(.close-button, .back-button) {
          display: flex;
          align-items: center;
        }
        .topbar :global(.close-button i) {
          margin-left: 5px;
          margin-right: 0;
        }
        .topbar :global(.button-icon) {
          line-height: inherit;
          font-size: 1.5rem;
        }
      `}</style>
    </div>
  );
}

export default Top;
