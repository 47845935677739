import Decimal from 'decimal.js';
import { calculateMileageRate } from './calculateMileageRate';
export function calculateMileageRates({ mileages, previousMileageDistanceKm }) {
    let previousMileageKm = previousMileageDistanceKm;
    let totalMileageKm = new Decimal(0);
    let mileageTotalDkk = new Decimal(0);
    const result = [];
    mileages.forEach((mileage) => {
        const distanceKm = mileage.distanceKm;
        let mileageRate = calculateMileageRate(previousMileageKm, mileage.distanceKm, mileage.date);
        totalMileageKm = totalMileageKm.plus(distanceKm);
        previousMileageKm = previousMileageKm.plus(distanceKm);
        mileageTotalDkk = mileageTotalDkk.plus(mileageRate.times(distanceKm));
        result.push({
            id: mileage.id,
            rate: mileageRate,
            totalDkk: mileageRate.times(distanceKm)
        });
    });
    return result;
}
export default calculateMileageRates;
