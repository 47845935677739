import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';
import ButtonGroupTextField from '~/features/shared/components/forms/ButtonGroupTextField';
import SelectField from '~/features/shared/components/forms/SelectField';
import useSalarySettingsForm from './useSalarySettingsForm';

type TaxInfoSectionProps = Pick<ReturnType<typeof useSalarySettingsForm>, 'isRequired'>;

export default function TaxInfoSection({ isRequired }: TaxInfoSectionProps) {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation('users');

  const canUpdateTaxInfo = watch('canUpdateTaxInfo');

  const taxCardOptions = [
    { value: 'MAIN', label: t('taxCard.MAIN') },
    { value: 'SECONDARY', label: t('taxCard.SECONDARY') }
  ];

  return (
    <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-2">
      <ButtonGroupTextField
        label={t('labels.personId.dk')}
        name="personId"
        required={isRequired('personId')}
        editable={canUpdateTaxInfo}
        viewable
        hiddenValue={watch('personIdHidden')}
        onEditClick={() => setValue('personId', '')}
      />
      <SelectField
        name="taxCard"
        label={t('labels.taxCard')}
        options={taxCardOptions}
        tooltip={t('descriptions.taxCard')}
        required={isRequired('taxCard')}
      />
    </div>
  );
}
