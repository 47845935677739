import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import cn from '~/shadcn/utils';
import useOnboardingTaskList, { Step } from '../hooks/useOnboardingTaskList';
import PaymentInfoSettingsModal from '~/features/account/components/PaymentInfoSettingsModal/PaymentInfoSettingsModal';

function Item({ step, index }: { step: Step; index: number }) {
  const crossedOut = step.completed;
  const itemText = `${index + 1}. ${step.title}`;
  const itemClasses = cn(' ', {
    'tw-line-through tw-text-neutral-500': crossedOut,
    'tw-text-white': !crossedOut
  });
  let itemComponent = null;

  if (step.completed || (!step.link && !step.onClick)) {
    itemComponent = <div className={itemClasses}>{itemText}</div>;
  }
  if (step.onClick && !step.completed) {
    itemComponent = (
      <button className={cn('hover:tw-text-neutral-300', itemClasses)} onClick={step.onClick} type="button">
        {itemText}
      </button>
    );
  }
  if (step.link && !step.completed) {
    itemComponent = (
      <Link href={step?.link} className={cn('hover:tw-text-neutral-300', itemClasses)}>
        {itemText}
      </Link>
    );
  }

  return (
    <li
      className="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-text-sm tw-font-normal tw-border-t tw-border-neutral-500 tw-hover:tw-bg-neutral-900/70"
      key={step.title}
    >
      <div>{itemComponent}</div>
    </li>
  );
}

export default function NavOnboardingTaskList() {
  const { t } = useTranslation();
  const [showPaymentInfoSettingsModal, setShowPaymentInfoSettingsModal] = useState(false);

  const { steps, loading, error, data, hasInvoice, showTodoList } = useOnboardingTaskList({
    setShowPaymentInfoSettingsModal
  });

  if (hasInvoice) return null;

  if (loading || !data || error || !showTodoList || hasInvoice) return null;

  return (
    <div className="tw-pb-5 tw-transition-none">
      <div className="tw-mx-8 tw-mt- tw-bg-neutral-900/70 tw-rounded-lg tw-flex tw-flex-col">
        <div className="tw-px-4 tw-py-3 tw-text-white tw-archivo-black-font tw-text-sm">
          {t('onboarding.todoList')}
        </div>
        <ol className="tw-list-decimal tw-list-inside tw-pl-0 tw-mb-0">
          {steps.map((step, index) => (
            <Item step={step} key={step.title} index={index} />
          ))}
        </ol>
      </div>
      <PaymentInfoSettingsModal
        open={showPaymentInfoSettingsModal}
        onClose={() => setShowPaymentInfoSettingsModal(false)}
      />
    </div>
  );
}
