import { createContext } from 'react';

export interface ContentLoaderState {
  loading: boolean;
  subtitle?: string;
}

export interface AppContextState {
  startContentLoading: (subtitle?: ContentLoaderState['subtitle']) => void;
  finishContentLoading: () => void;
  contentLoaderState: ContentLoaderState;
}

export default createContext<AppContextState | null>(null);
