import { useCallback } from 'react';
import useUser from '~/contexts/User/useUser';
import getFeatureOverride from './featureOverride';
import { FeatureKeys, FeatureValueKeys } from '~/constants/features';

export default function useFeatures() {
  const { features } = useUser();

  const isEnabled = useCallback(
    (key: FeatureKeys) => {
      const mappedKey = key.replace('!', '') as FeatureKeys;
      const featureOverride = getFeatureOverride(mappedKey);
      const value =
        typeof featureOverride === 'boolean' ? featureOverride === true : features?.[mappedKey] === true;
      return key.startsWith('!') ? !value : value;
    },
    [features]
  );

  const getValue = useCallback(
    (key: FeatureKeys | FeatureValueKeys) => {
      const featureOverride = getFeatureOverride(key);
      return featureOverride ?? features?.[key];
    },
    [features]
  );

  return {
    isEnabled,
    getValue
  };
}
