import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import useWindowDimensions from '~/features/shared/hooks/useWindowDimensions';
import SidebarContext, { FocusModeState, ForceParams } from './SidebarContext';

const MOBILE_SIDEBAR_BREAKPOINT = 1200;

export default function SidebarContextProvider({ children }: { children: React.ReactNode }) {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [sidebarMiniMode, setSidebarMiniMode] = useState(false);
  const [isFocusMode, setFocusMode] = useState<FocusModeState>({ value: false });
  const { width } = useWindowDimensions();
  const router = useRouter();
  const closeFocusHandler = useRef<() => void | null>(null);
  const backFocusHandler = useRef<() => void | null>(null);

  const toggleMenu = useCallback(
    (params?: ForceParams) => {
      if (params?.force || width < MOBILE_SIDEBAR_BREAKPOINT) {
        setSidebarOpen((prev) => !prev);
      } else {
        setSidebarMiniMode((prev) => !prev);
      }
    },
    [width, setSidebarMiniMode, setSidebarOpen]
  );

  const closeMenu = useCallback(
    (params?: ForceParams) => {
      if (params?.force || width < MOBILE_SIDEBAR_BREAKPOINT) {
        setSidebarOpen(false);
      }
    },
    [width]
  );

  const setSidebarFocusMode = useCallback((value: boolean, options?: FocusModeState['options']) => {
    setFocusMode({ value, options });
    if (value) {
      setSidebarOpen(false);
      setSidebarMiniMode(false);
    }
  }, []);

  const setSidebarFocusModeOptions = useCallback((options?: FocusModeState['options']) => {
    setFocusMode((prevState) => ({
      value: prevState.value,
      options
    }));
  }, []);

  const onFocusBack = useCallback(() => {
    if (isFocusMode.value) {
      backFocusHandler.current?.();
    }
  }, [isFocusMode.value]);

  const onFocusClose = useCallback(() => {
    if (isFocusMode.value) {
      closeFocusHandler.current?.();
      setFocusMode({ value: false });
      setSidebarOpen(true);
    }
  }, [isFocusMode.value]);

  const closeOnKeyPress = useCallback((event: any) => {
    if (event.key === 'Enter') {
      setSidebarOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isFocusMode.value) return;

    if (width < MOBILE_SIDEBAR_BREAKPOINT) {
      setSidebarMiniMode(false);
      setSidebarOpen(false);
    } else {
      setSidebarMiniMode(false);
      setSidebarOpen(true);
    }
  }, [isFocusMode.value, width]);

  // close the menu when the route changes.
  useEffect(() => {
    const handleRouteChange = () => {
      closeMenu();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, closeMenu]);

  const context = useMemo(
    () => ({
      closeMenu,
      toggleMenu,
      setFocusMode: setSidebarFocusMode,
      setFocusModeOptions: setSidebarFocusModeOptions,
      closeOnKeyPress,
      onFocusBack,
      setFocusBackHandler: (handler: (() => void) | null) => {
        // @ts-ignore
        backFocusHandler.current = handler;
      },
      onFocusClose,
      setFocusCloseHandler: (handler: (() => void) | null) => {
        // @ts-ignore
        closeFocusHandler.current = handler;
      },
      isFocusMode: isFocusMode.value,
      focusModeOptions: isFocusMode.options,
      isSidebarOpen,
      sidebarMiniMode
    }),
    [
      closeMenu,
      toggleMenu,
      setSidebarFocusMode,
      setSidebarFocusModeOptions,
      closeOnKeyPress,
      onFocusBack,
      onFocusClose,
      isFocusMode.value,
      isFocusMode.options,
      isSidebarOpen,
      sidebarMiniMode
    ]
  );

  return <SidebarContext.Provider value={context}>{children}</SidebarContext.Provider>;
}
