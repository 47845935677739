import { cn } from '~/shadcn/utils';
import useApp from '~/contexts/App/useApp';
import Loader from '~/features/shared/components/Loader';

export default function ContentLoader() {
  const { contentLoaderState } = useApp();

  if (!contentLoaderState.loading) return null;

  return (
    <div
      className={cn(
        // Position
        'tw-fixed tw-top-0 tw-right-0 tw-bottom-0 tw-left-0',

        // Display
        'tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6',

        // Backdrop
        'tw-backdrop-blur tw-bg-background/80 tw-z-contentLoader',

        // Font
        'tw-text-base'
      )}
    >
      <Loader />
      {contentLoaderState.subtitle && <p>{`${contentLoaderState.subtitle}...`}</p>}
    </div>
  );
}
