/**
 * @see https://developers.zenegy.com/docs/employees-1#tax-cards
 */
import { TaxCardStatus, TaxCardType } from '@prisma/client';
export const ZenegyTaxCardStatus = {
    [TaxCardStatus.PENDING]: 0,
    [TaxCardStatus.COMPLETED]: 1,
    [TaxCardStatus.ERROR]: 2
};
export const ZenegyTaxCardType = {
    [TaxCardType.NONE]: 0,
    [TaxCardType.GROSS_PULL_CARD]: 1,
    [TaxCardType.MAIN]: 2,
    [TaxCardType.FRIKORT]: 3,
    [TaxCardType.SECONDARY]: 4,
    [TaxCardType.MAIN_WITH_SEPARATE_PERCENT]: 5,
    [TaxCardType.SECONDARY_WITH_SEPARATE_PERCENT]: 6 // Secondary tax card with a separate tax rate
};
