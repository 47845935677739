import { cn } from '~/shadcn/utils';
import Loader from './Loader';

interface LoadingComponentProps {
  extraPadding?: boolean;
}

export default function LoadingComponent({ extraPadding }: LoadingComponentProps) {
  return (
    <div
      className={cn('tw-flex tw-items-center tw-justify-center tw-h-full', {
        'tw-py-16': extraPadding,
        'tw-pt-8': !extraPadding
      })}
    >
      <Loader />
    </div>
  );
}
