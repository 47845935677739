import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import useUser from '~/contexts/User/useUser';
import OnboardingQuery from './graphql/OnboardingQueryV2.gql';
import { OnboardingQueryV2 as OnboardingQueryType } from './graphql/.generated/OnboardingQueryV2';
import { skipOnboardingReferralList } from '../utils';

type StepId = 'complete-profile' | 'create-customer' | 'create-task' | 'submit-invoice' | 'complete';

export type Step =
  | {
      id: StepId;
      title: string;
      link?: undefined;
      onClick?: () => void;
      completed: boolean;
      active: boolean;
      description?: string;
      previousIsActive?: boolean;
    }
  | {
      id: StepId;
      title: string;
      link?: string;
      onClick?: undefined;
      completed: boolean;
      active: boolean;
      description?: string;
      previousIsActive?: boolean;
    }
  | {
      id: StepId;
      title: string;
      link?: undefined;
      onClick?: undefined;
      completed: boolean;
      active: boolean;
      description?: string;
      previousIsActive?: boolean;
    };

type OnboardingTaskListProps = {
  setShowPaymentInfoSettingsModal: Dispatch<SetStateAction<boolean>>;
};
export default function useOnboardingTaskList({ setShowPaymentInfoSettingsModal }: OnboardingTaskListProps) {
  const { id: userId, referral, latestSentInvoiceDate } = useUser();
  const {
    query: { show_onboarding: showOnboarding }
  } = useRouter();
  const referralTag = referral?.trim().toLocaleLowerCase();
  const hasPreviousInvoice = latestSentInvoiceDate !== null;
  const skipOnboardingUser = referralTag && skipOnboardingReferralList.includes(referralTag);
  const showTodoList = (!hasPreviousInvoice && !skipOnboardingUser) || showOnboarding;

  const { t } = useTranslation();

  const { data, error, loading } = useQuery<OnboardingQueryType>(OnboardingQuery, {
    variables: { userId },
    skip: !showTodoList,
    canonizeResults: true
  });

  const profileComplete =
    !!data?.currentUser.bankAccountHidden && !!data.currentUser.taxInfoHidden && !!data.currentUser.address;

  const hasCustomer = (data?.customers.totalCount ?? 0) > 0;
  const hasTask = (data?.tasks.totalCount ?? 0) > 0;
  const hasInvoice = (data?.invoices.totalCount ?? 0) > 0;
  const draftInvoiceId = data?.draftInvoices?.edges?.[0]?.node?.id;

  const steps = useMemo<Step[]>(
    () => [
      {
        id: 'complete-profile',
        title: t('onboarding.completeProfileV2.title'),
        description: t('onboarding.completeProfileV2.description'),
        onClick: () => {
          setShowPaymentInfoSettingsModal(true);
        },
        completed: profileComplete,
        active: !profileComplete,
        previousIsActive: true
      },
      {
        id: 'create-customer',
        title: t('onboarding.createCustomerV2.title'),
        description: t('onboarding.createCustomerV2.description'),
        link: '/customers/new?return_url=/',
        completed: hasCustomer,
        active: !hasCustomer,
        // this should always show up as the first step
        previousIsActive: false
      },
      {
        id: 'create-task',
        title: t('onboarding.createTaskV2.title'),
        description: t('onboarding.createTaskV2.description'),
        link: '/tasks/new?return_url=/',
        completed: hasTask,
        active: !hasTask,
        previousIsActive: !hasCustomer
      },
      {
        id: 'submit-invoice',
        title: t('onboarding.submitInvoiceV2.title'),
        description: t('onboarding.submitInvoiceV2.description'),
        link: draftInvoiceId ? `/invoices/${draftInvoiceId}` : '/invoices/new?return_url=/&submit_modal=true',
        completed: hasInvoice,
        active: !hasInvoice,
        previousIsActive: !hasTask
      },
      {
        id: 'complete',
        title: t('onboarding.completeV2.title'),
        description: t('onboarding.completeV2.description'),
        completed: false,
        active: hasInvoice,
        previousIsActive: !hasInvoice
      }
    ],
    [t, profileComplete, hasCustomer, hasTask, draftInvoiceId, hasInvoice, setShowPaymentInfoSettingsModal]
  );

  const stepsObject = useMemo(
    () => steps.reduce((acc, step) => ({ ...acc, [step.id]: step }), {} as Record<StepId, Step>),
    [steps]
  );

  return {
    steps,
    stepsObject,
    loading,
    error,
    data,
    hasInvoice,
    showTodoList
  };
}
