import { useFormContext } from 'react-hook-form';
import RequiredSymbol from './RequiredSymbol';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/shadcn/ui/form';
import { Combobox, ComboboxProps } from '~/shadcn/ui/combobox';
import InfoTooltip from './InfoTooltip';

type ComboboxFieldProps = Pick<
  ComboboxProps,
  'disabled' | 'emptyMessage' | 'options' | 'placeholder' | 'filter' | 'searchPlaceholder'
> & {
  name: string;
  label: string | JSX.Element;
  description?: string;
  tooltip?: string;
  required?: boolean;
};

export default function ComboboxField({
  name,
  label,
  description,
  tooltip,
  options,
  required,
  placeholder,
  searchPlaceholder,
  filter,
  disabled,
  emptyMessage
}: ComboboxFieldProps) {
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext();

  const isInvalid = Boolean(errors[name]);

  return (
    <FormField
      control={control}
      name={name}
      aria-invalid={isInvalid}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel htmlFor={name} className="tw-flex tw-items-center tw-gap-1 tw-h-5">
            {label} {required && <RequiredSymbol />}
            {tooltip && <InfoTooltip text={tooltip} />}
          </FormLabel>
          <FormControl>
            <Combobox
              className="!tw-mt-1"
              options={options}
              value={field.value}
              onChange={(option) => {
                setValue(name, option.value);
              }}
              disabled={disabled}
              placeholder={placeholder}
              searchPlaceholder={searchPlaceholder}
              emptyMessage={emptyMessage}
              filter={filter}
              name={name}
              invalid={isInvalid}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
