import { useFormContext } from 'react-hook-form';
import RequiredSymbol from './RequiredSymbol';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/shadcn/ui/form';
import { Input, InputProps } from '~/shadcn/ui/input';
import InfoTooltip from './InfoTooltip';
import { cn } from '~/shadcn/utils';

export type TextFieldProps = Pick<InputProps, 'autoFocus' | 'min' | 'pattern' | 'onKeyDown'> & {
  label: string | undefined;
  name: string;
  className?: string;
  type?: 'text' | 'email' | 'number' | 'tel' | 'password' | 'search';
  description?: string;
  placeholder?: string;
  required?: boolean;
  readOnly?: boolean;
  tooltip?: string;
  onBlur?: () => void;
  disabled?: boolean;
  nonFocusable?: boolean;
};

export default function TextField({
  name,
  type = 'text',
  label,
  className,
  description,
  tooltip,
  placeholder,
  required,
  readOnly,
  disabled,
  onBlur,
  nonFocusable,
  ...inputProps
}: TextFieldProps) {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  const isInvalid = Boolean(errors[name]);

  return (
    <FormField
      control={control}
      name={name}
      disabled={disabled}
      aria-invalid={isInvalid}
      render={({ field }) => (
        <FormItem>
          {label && (
            <FormLabel htmlFor={name} className="tw-flex tw-items-center tw-gap-1 tw-h-5">
              {label} {required && <RequiredSymbol />} {tooltip && <InfoTooltip text={tooltip} />}
            </FormLabel>
          )}
          <FormControl>
            <Input
              {...field}
              onBlur={onBlur}
              type={type}
              readOnly={readOnly}
              placeholder={placeholder}
              className={cn('!tw-mt-1', className)}
              invalid={isInvalid}
              tabIndex={nonFocusable ? -1 : undefined}
              {...inputProps}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
