import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useFeatures from '~/features/shared/hooks/useFeatures';
import { PageItem } from './PageItem';

type MenuItemProps = {
  className?: string;
  anchorClassName?: string;
  page: PageItem;
};

function MenuItem({ page, className = undefined, anchorClassName = undefined }: MenuItemProps) {
  const { isEnabled } = useFeatures();

  const { pathname } = useRouter();

  let featureDisabled = false;
  if (page.featureFlag) {
    featureDisabled = !isEnabled(page.featureFlag);
  }

  const active = page.href === pathname;
  const { Icon } = page;

  return (
    <li className={classNames('side-nav-item', className, { 'd-none': featureDisabled })}>
      <Link
        href={page.href}
        target={page.target ? page.target : undefined}
        className={classNames(
          'side-nav-link !tw-flex tw-items-center',
          anchorClassName,
          page.anchorClassName,
          { active }
        )}
      >
        {Icon && <Icon className="me-2" width={20} strokeWidth={1.6} absoluteStrokeWidth />}
        <span>{page.title}</span>
        {page.badge && <span className="tw-flex tw-items-center tw-ml-1">{page.badge}</span>}
      </Link>
    </li>
  );
}

export default MenuItem;
