import { useFormContext } from 'react-hook-form';
import RequiredSymbol from './RequiredSymbol';
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '~/shadcn/ui/form';
import ButtonGroupInput, { ButtonGroupInputProps } from './ButtonGroupInput';
import { TextFieldProps } from './TextField';
import InfoTooltip from './InfoTooltip';

type ButtonGroupTextFieldProps = TextFieldProps & Omit<ButtonGroupInputProps, 'inputProps'>;

export default function ButtonGroupTextField({
  className,
  name,
  disabled,
  label,
  description,
  tooltip,
  required,

  editable,
  viewable,
  hiddenValue,
  onEditClick,

  ...inputProps
}: ButtonGroupTextFieldProps) {
  const {
    control,
    register,
    formState: { errors }
  } = useFormContext();

  const isInvalid = Boolean(errors[name]);

  return (
    <FormField
      {...register(name)}
      control={control}
      name={name}
      disabled={disabled}
      aria-invalid={isInvalid}
      render={({ field }) => (
        <FormItem>
          <FormLabel htmlFor={name} className="tw-flex tw-items-center tw-gap-1 tw-h-5">
            {label} {required && <RequiredSymbol />} {tooltip && <InfoTooltip text={tooltip} />}
          </FormLabel>
          <FormControl>
            <ButtonGroupInput
              className="!tw-mt-1"
              inputProps={{
                ...field,
                ...inputProps
              }}
              disabled={disabled}
              editable={editable}
              viewable={viewable}
              hiddenValue={hiddenValue}
              onEditClick={onEditClick}
              invalid={isInvalid}
              name={name}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
